import React, { useEffect, useState } from "react";
import Main from "./components/main";
import Details from "./components/details";
import { QUERY_FORMS_LIST, QUERY_FORM_DETAILS, QUERY_MATERIALS_LIST } from "../../queries";
import { useLazyQuery } from "@apollo/react-hooks";
import { RouteComponentProps } from "react-router-dom";

const Forms = (props: RouteComponentProps): JSX.Element => {
  const [getFormsList, queryFormsList] = useLazyQuery(QUERY_FORMS_LIST, { fetchPolicy: "no-cache" });

  const [getMaterialsList, queryMaterialsList] = useLazyQuery(QUERY_MATERIALS_LIST, { fetchPolicy: "no-cache" });

  const [getFormsDetails, queryFormsDetails] = useLazyQuery(QUERY_FORM_DETAILS, { fetchPolicy: "no-cache" });

  const [onViewForm, setOnViewForm] = useState<string>("");

  const fetchData = async (): Promise<void> => {
    getFormsList();
    getMaterialsList();
  };

  useEffect(() => {
    fetchData();
    /* eslint-disable-next-line */
  }, []);

  const onClickViewForm = (id: string): void => {
    setOnViewForm(id);
    getFormsDetails({ variables: { id } });
  };

  const onClickBackToList = (): void => {
    setOnViewForm("");
  };

  const onClickAddForm = (): void => {
    props.history.push("/form");
  };

  if(onViewForm){
    return (
      <Details
        queryFormsDetails={queryFormsDetails}
        onClickBackToList={onClickBackToList}
        queryMaterialsList={queryMaterialsList}
      />
    );
  }

  return (
    <Main
      onClickViewForm={onClickViewForm}
      queryFormsList={queryFormsList}
      onClickAddForm={onClickAddForm}
    />
  );
};

export default Forms;
