import React from "react";

interface Props {
  isVisible: boolean;
  children: any;
}

const ShowHide = (props: Props): JSX.Element => {
  return (
    <div style={!props.isVisible ? { display: "none" } : undefined}>
      {props.children}
    </div>
  );
};

export default ShowHide;
