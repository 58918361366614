import React from "react";
import { withRouter } from "react-router-dom";
import Main from "./components/";

const NotFoundPage = (props: any): JSX.Element => {
  const onClickBackToHome = (): void => {
    props.history.push("/");
  };

  return (
    <Main
      onClickBackToHome={onClickBackToHome}
    />
  );
};

export default withRouter(NotFoundPage);
