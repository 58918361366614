/* eslint-disable max-len */

import { gql } from "@apollo/client";

export const MUTATION_CREATE_FROM = gql`
  mutation createForm($device: String!, $user: Int!, $shift: String, $installed_blades: String, $note: String, $suitable: Boolean, $date: String, $status: Int!, $stops: [FormStop], $materials: [FormMaterialInput]) {
    createForm(device: $device, user: $user, shift: $shift, installed_blades: $installed_blades, note: $note, suitable: $suitable, date: $date, status: $status, stops: $stops, materials: $materials) {
      status
      id
    }
  }
`;

export const MUTATION_CREATE_USER = gql`
  mutation createUser($email: String!, $password: String!, $name: String!, $type: Int!, $status: Int!) {
    createUser(email: $email, password: $password, name: $name, type: $type, status: $status) {
      status
      id
    }
  }
`;

export const MUTATION_UPDATE_USER = gql`
  mutation updateUser($email: String!, $password: String!, $name: String!, $type: Int!, $id: ID!) {
    updateUser(email: $email, password: $password, name: $name, type: $type, id: $id) {
      status
      id
    }
  }
`;

export const MUTATION_CREATE_MATERIAL = gql`
  mutation createMaterial($name: String!) {
    createMaterial(name: $name) {
      status
      id
    }
  }
`;

export const MUTATION_UPDATE_MATERIAL = gql`
  mutation updateMaterial($name: String!, $id: ID!) {
    updateMaterial(name: $name, id: $id) {
      status
      id
    }
  }
`;

export const MUTATION_UPDATE_SETTING = gql`
  mutation updateSettings($id: ID!, $value: String!) {
    updateSettings(id: $id, value: $value) {
      status
    }
  }
`;

export const MUTATION_UPDATE_USER_STATUS = gql`
  mutation updateUserStatus($status: Int!, $id: ID!) {
    updateUserStatus(status: $status, id: $id) {
      status
    }
  }
`;

export const MUTATION_LOGOUT = gql`
  mutation logout {
    logout {
      status
    }
  }
`;

export const MUTATION_LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      status
      access_token
      token_type
      expires_at
    }
  }
`;

export const QUERY_DEVICES_LIST = gql`
  {
    devicesList
  }
`;

export const QUERY_SHIFTS_LIST = gql`
  {
    shiftsList
  }
`;

export const QUERY_MATERIALS_LIST = gql`
  {
    materialsList {
      id
      name
    }
  }
`;

export const QUERY_SETTINGS_LIST = gql`
  {
    settingsList {
      id
      key
      value
    }
  }
`;

export const QUERY_FORMS_LIST = gql`
  {
    formsList {
      id
      user {
        id
        email
        name
      }
      shift
      date
      status
    }
  }
`;

export const QUERY_FORMS_PER_DATE_RANGE_LIST = gql`
  query formsPerDateRangeList($start: String!, $end: String!) {
    formsPerDateRangeList(start: $start, end: $end) {
      id
      device
      materials {
        time
        material_id
        quantity_produced
        quantity_purged
      }
      stops {
        start
        end
        reason
      }
      user {
        name
      }
      shift
      installed_blades
      note
      suitable
      date
      status
    }
  }
`;

export const QUERY_USERS_LIST = gql`
  {
    usersList {
      id
      email
      name
      type {
        type
        status
      }
    }
  }
`;

export const QUERY_PENDING_FORM_DETAILS = gql`
  {
    pendingFormDetails {
      id
      device
      materials {
        time
        material_id
        quantity_produced
        quantity_purged
      }
      stops {
        start
        end
        reason
      }
      shift
      installed_blades
      note
      suitable
      date
      status
    }
  }
`;

export const QUERY_FORM_DETAILS = gql`
  query getFormDetails($id: ID!) {
    formDetails(id: $id) {
      id
      device
      materials {
        time
        material_id
        quantity_produced
        quantity_purged
      }
      stops {
        start
        end
        reason
      }
      user {
        name
      }
      shift
      installed_blades
      note
      suitable
      date
      status
    }
  }
`;

export const QUERY_FORMS_PER_DATE_LIST = gql`
  query formsPerDateList($date: String!) {
    formsPerDateList(date: $date) {
      id
      device
      user {
        id
        email
        name
      }
      materials {
        material {
          id
          name
        }
        quantity_produced
        quantity_purged
      }
      shift
      date
      status
    }
  }
`;

export const QUERY_DEVICES_STATUSES = gql`
  query devicesStatus($start: String!) {
    devicesStatuses(start: $start) {
      device
      data
    }
  }
`;

export const QUERY_DEVICES_LIVE_DATA = gql`
  query deviceLiveData($device: String!) {
    deviceLiveData(device: $device) {
      device
      data
    }
  }
`;

export const QUERY_DELTAS_PER_DATE_LIST = gql`
  query deltasPerDateList($date: String!) {
    deltasPerDateList(date: $date) {
      data
    }
  }
`;

export const QUERY_AGGREGATED_MONTH_DELTAS = gql`
  query aggregatedMonthDeltas($start: String!, $end: String!) {
    aggregatedMonthDeltas(start: $start, end: $end) {
      data
    }
  }
`;

export const QUERY_LOG_DELTAS_PER_DATE_LIST = gql`
  query logDeltasPerDateList($date: String!, $device: String!) {
    logDeltasPerDateList(date: $date, device: $device) {
      data
    }
  }
`;

export const QUERY_LOG_SENSORS_PER_DATE_LIST = gql`
  query logSensorsPerDateList($date: String!, $device: String!) {
    logSensorsPerDateList(date: $date, device: $device) {
      data
    }
  }
`;

export const QUERY_DEVICE_SETTINGS = gql`
  {
    deviceSettings {
      data
    }
  }
`;

export const QUERY_DEVICE_JOBS_LIST = gql`
  {
    deviceJobs {
      id
      device
      status
      description
      quantity
      data
      note
    }
  }
`;

export const MUTATION_CREATE_DEVICE_JOB = gql`
  mutation createDeviceJob($device: String!, $description: String!, $quantity: Float!, $data: String!, $note: String) {
    createDeviceJob(device: $device, description: $description, quantity: $quantity, data: $data, note: $note) {
      status
      id
    }
  }
`;

export const MUTATION_DELETE_DEVICE_JOB = gql`
  mutation deleteDeviceJob($id: ID!) {
    deleteDeviceJob(id: $id) {
      status
      message
    }
  }
`;

export const MUTATION_START_WORK = gql`
  mutation startWork {
    startWork {
      status
      message
    }
  }
`;

export const MUTATION_IMPORT_DELTAS = gql`
  mutation importDeltas($data: String!) {
    importDeltas(data: $data) {
      status
      message
    }
  }
`;
