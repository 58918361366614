import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ApolloProvider } from "@apollo/react-hooks";
import client from "../../client";
import Layout from "./components/layout";
import PrivateRoute from "../private-route";
import Home from "../home";
import Devices from "../devices";
import Login from "../login";
import ErrorPage from "../error-page";
import NotFoundPage from "../not-found-page";
import Plc from "../plc";
import Log from "../log";
import Form from "../form";
import Forms from "../forms";
import RawData from "../raw-data";
import LiveData from "../live-data";
import Users from "../users";
import Settings from "../settings";
import Materials from "../materials";
import Jobs from "../jobs";
import ImportDeltas from "../import-deltas";

class Application extends Component {
  componentDidCatch(): void {
    if(process.env.NODE_ENV === "production") window.location.assign("/error-page");
  }

  render(): JSX.Element {
    return (
      <ApolloProvider client={client}>
        <BrowserRouter>
          <Layout>
            <Switch>
              <PrivateRoute exact path="/" component={Home} userType={1} />
              <PrivateRoute exact path="/devices" component={Devices} userType={1} />
              <PrivateRoute exact path="/log" component={Log} userType={1} />
              <PrivateRoute exact path="/raw-data" component={RawData} userType={1} />
              <PrivateRoute exact path="/live-data" component={LiveData} userType={1} />
              <PrivateRoute exact path="/forms" component={Forms} userType={1} />
              <PrivateRoute exact path="/users" component={Users} userType={1} />
              <PrivateRoute exact path="/settings" component={Settings} userType={1} />
              <PrivateRoute exact path="/materials" component={Materials} userType={1} />
              <PrivateRoute exact path="/plc" component={Plc} userType={2} />
              <PrivateRoute exact path="/form" component={Form} userType={2} />
              <PrivateRoute exact path="/jobs" component={Jobs} userType={2} />
              <PrivateRoute exact path="/import-deltas" component={ImportDeltas} userType={2} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/error-page" component={ErrorPage} />
              <Route component={NotFoundPage} />
            </Switch>
          </Layout>
        </BrowserRouter>
      </ApolloProvider>
    );
  }
}

export default Application;
