import React from "react";
import { withRouter } from "react-router-dom";
import Main from "./components/";

const ErrorPage = (props: any): JSX.Element => {
  const onClickBackToLogin = (): void => {
    localStorage.removeItem("access_token");
    props.history.push("/login");
  };

  return (
    <Main
      onClickBackToLogin={onClickBackToLogin}
    />
  );
};

export default withRouter(ErrorPage);
