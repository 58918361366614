import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { MUTATION_UPDATE_SETTING, QUERY_SETTINGS_LIST } from "../../queries";
import Main from "./components/main";

const Settings = (): JSX.Element => {
  const [isEmailEnabled, setIsEmailEnabled] = useState<boolean>(false);

  const initializeForm = (data: any): void => setIsEmailEnabled(data.settingsList[0].value === "1");

  const afterSavingSettings = (): void => setIsEmailEnabled(!isEmailEnabled);

  const [getSettingsList, querySettingsList] = useLazyQuery(QUERY_SETTINGS_LIST, { fetchPolicy: "no-cache", onCompleted: initializeForm });

  const [updateSettings] = useMutation(MUTATION_UPDATE_SETTING, { onCompleted: afterSavingSettings });

  const fetchData = async (): Promise<void> => {
    getSettingsList();
  };

  useEffect(() => {
    fetchData();
    /* eslint-disable-next-line */
  }, []);

  const onChangeEnableEmail = (): void => {
    const query: any = querySettingsList;
    updateSettings({ variables: { id: query.data.settingsList[0].id, value: !isEmailEnabled ? "1" : "0" } });
  };

  return (
    <Main
      isLoading={querySettingsList.loading}
      isEmailEnabled={isEmailEnabled}
      onChangeEnableEmail={onChangeEnableEmail}
    />
  );
};

export default Settings;
