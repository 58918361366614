/* eslint-disable max-len */

import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import style from "./style.module.scss";

interface Props {
  onReceiveFileData: any;
}

const Main = (props: Props): JSX.Element => {
  const onChangeFile = (changeEvent: any): void => {
    const selectedFile = changeEvent.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();

      reader.onload = (readEvent: any): void => {
        const fileData = readEvent?.target.result;
        props.onReceiveFileData(selectedFile, fileData);
      };

      reader.readAsText(selectedFile);
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <div className={style.headerWrapper}>
            <Paper elevation={2}>
              <div className={style.mainActionsWrapper}>
                <span className={style.label}>Seleziona il file da importare</span>
                <input type="file" onChange={onChangeFile} />
              </div>
            </Paper>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default Main;
