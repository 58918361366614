/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-use-before-define */

import React, { useEffect, useState } from "react";
import FormComponent from "./components/form";
import { QUERY_DEVICES_LIST, QUERY_SHIFTS_LIST, QUERY_MATERIALS_LIST, MUTATION_CREATE_FROM, QUERY_PENDING_FORM_DETAILS, QUERY_USERS_LIST } from "../../queries";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { getCurrentDate, getDeviceShortName, isNotBetweenTimes, isValidTime, isValidDate, isUserAuthorized } from "../../utils";

const adaptFormValuesToBeSent = (device: string, formValues: any, status: number): any => {
  const deviceFormValues: any = formValues.devicesFormsValues.find((deviceFormValue: DeviceFormValues): boolean => deviceFormValue.device === device);
  const materials = [
    {
      time: "",
      material_id: deviceFormValues.material || 0,
      quantity_produced: deviceFormValues.quantity || 0,
      quantity_purged: 0,
    },
    ...deviceFormValues.additionalFormMateriels.map((item: any) => (
      {
        time: item.time || "",
        material_id: item.material || 0,
        quantity_produced: item.quantity || 0,
        quantity_purged: item.purgedQuantity || 0,
      }
    )),
  ];
  const stops: any = deviceFormValues.formStops;
  return {
    device,
    date: formValues.date,
    shift: formValues.shift,
    user: formValues.user,
    installed_blades: deviceFormValues.noteOne,
    note: deviceFormValues.noteTwo,
    suitable: deviceFormValues.suitable,
    status,
    materials,
    stops,
  };
};

const adaptFormToBeLoaded = (data: any): any => {
  const result: any = {};
  result.date = data[0].date;
  result.shift = data[0].shift;
  result.user = 0;
  result.devicesFormsValues = [];
  data.forEach((item: any): void => {
    const devicesFormsValue = {
      device: item.device,
      noteOne: item.installed_blades,
      noteTwo: item.note,
      suitable: item.suitable,
      quantity: (item.materials.length && item.materials[0].quantity_produced) || 0,
      material: (item.materials.length && item.materials[0].material_id) || 0,
      additionalFormMateriels: item.materials.slice(1).map((material: any): any => (
        {
          time: material.time || "",
          material: material.material_id,
          quantity: material.quantity_produced,
          purgedQuantity: material.quantity_purged,
        }
      )),
      formStops: item.stops.map((stop: any): any => (
        {
          start: stop.start,
          end: stop.end,
          reason: stop.reason,
        }
      )),
    };
    result.devicesFormsValues.push(devicesFormsValue);
  });
  return result;
};

const validateFormValues = (formValues: FormValues | undefined): Array<string> => {
  const errors: Array<string> = [];
  if (isUserAuthorized(1) && !formValues?.user) errors.push("Inserire utente");
  if (!formValues?.date || !isValidDate(formValues.date)) errors.push("Inserire la data");
  if (!formValues?.shift) errors.push("Inserire il turno di lavoro");
  if (!formValues?.devicesFormsValues.length) errors.push("Inserire dati dei delco");
  formValues?.devicesFormsValues.forEach((deviceFormValues: DeviceFormValues): void => {
    if (!deviceFormValues.device) errors.push(`Inserire valori per Delco ${getDeviceShortName(deviceFormValues.device)}`);
    if (!deviceFormValues.material) errors.push(`Inserire materiale per Delco ${getDeviceShortName(deviceFormValues.device)}`);
    if (!deviceFormValues.quantity || isNaN(deviceFormValues.quantity)) errors.push(`Inserire quantità per Delco ${getDeviceShortName(deviceFormValues.device)}`);
    deviceFormValues.additionalFormMateriels?.forEach((additionalFormMaterial: AdditionalFormMaterial, index: number): void => {
      if (!additionalFormMaterial.time || !isValidTime(additionalFormMaterial.time)) errors.push(`Inserire orario per materiale addizionale numero ${index + 1} per Delco ${getDeviceShortName(deviceFormValues.device)}`);
      if (!additionalFormMaterial.material) errors.push(`Inserire materiale per materiale addizionale numero ${index + 1} per Delco ${getDeviceShortName(deviceFormValues.device)}`);
      if (additionalFormMaterial.purgedQuantity === undefined || isNaN(additionalFormMaterial.purgedQuantity ? additionalFormMaterial.purgedQuantity : "-" as any)) errors.push(`Inserire quantità di spurgo per materiale addizionale numero ${index + 1} per Delco ${getDeviceShortName(deviceFormValues.device)}`);
      if (!additionalFormMaterial.quantity || isNaN(additionalFormMaterial.quantity)) errors.push(`Inserire quantità per materiale addizionale numero ${index + 1} per Delco ${getDeviceShortName(deviceFormValues.device)}`);
    });
    deviceFormValues.formStops?.forEach((formStop: FormStop, index: number): void => {
      if (!formStop.end || !isValidTime(formStop.end) || formStop.end < formStop.start) errors.push(`Inserire fine fermo numero ${index + 1} per Delco ${getDeviceShortName(deviceFormValues.device)}`);
      if (!formStop.start || !isValidTime(formStop.start) || formStop.start > formStop.end) errors.push(`Inserire inizio fermo numero ${index + 1} per Delco ${getDeviceShortName(deviceFormValues.device)}`);
      if (!formStop.reason) errors.push(`Inserire ragione fermo numero ${index + 1} per Delco ${getDeviceShortName(deviceFormValues.device)}`);
    });
  });
  if (formValues?.shift) {
    const selectedShiftStart = formValues.shift.split(",")[0];
    const selectedShiftEnd = formValues.shift.split(",")[1];
    formValues?.devicesFormsValues.forEach((deviceFormValues: DeviceFormValues): void => {
      deviceFormValues.additionalFormMateriels?.forEach((additionalFormMaterial: AdditionalFormMaterial, index: number): void => {
        if (isNotBetweenTimes(additionalFormMaterial.time, selectedShiftStart, selectedShiftEnd)) errors.push(`Orario non valido per materiale addizionale numero ${index + 1} per Delco ${getDeviceShortName(deviceFormValues.device)}`);
      });
      deviceFormValues.formStops?.forEach((formStop: FormStop, index: number): void => {
        if (isNotBetweenTimes(formStop.end, selectedShiftStart, selectedShiftEnd)) errors.push(`Orario non valido per fine fermo numero ${index + 1} per Delco ${getDeviceShortName(deviceFormValues.device)}`);
        if (isNotBetweenTimes(formStop.start, selectedShiftStart, selectedShiftEnd)) errors.push(`Orario non valido per inizio fermo numero ${index + 1} per Delco ${getDeviceShortName(deviceFormValues.device)}`);
      });
    });
  }
  return errors;
};

export interface FormStop {
  start: string;
  end: string;
  reason: string;
}

export interface AdditionalFormMaterial {
  time: string;
  material: number;
  quantity: number;
  purgedQuantity: number;
}

export interface DeviceFormValues {
  device: string;
  noteOne: string;
  noteTwo: string;
  suitable: boolean;
  quantity: number;
  material: number;
  additionalFormMateriels?: Array<AdditionalFormMaterial>;
  formStops?: Array<FormStop>;
}

export interface FormValues {
  date: string;
  shift: string;
  user: number;
  devicesFormsValues: Array<DeviceFormValues>;
}

const Form = (): JSX.Element => {
  const [formValues, setFormValues] = useState<FormValues | undefined>(undefined);

  const [selectedDevice, setSelectedDevice] = useState<string>("");

  const initializeForm = (data: any): void => {
    const initialFormValues: FormValues = { date: getCurrentDate(), shift: "", devicesFormsValues: [], user: 0 };
    data.devicesList.forEach((device: string): void => {
      initialFormValues.devicesFormsValues.push(
        {
          device,
          noteOne: "",
          noteTwo: "",
          suitable: true,
          material: 0,
          quantity: 0,
          additionalFormMateriels: [],
          formStops: [],
        },
      );
    });
    setFormValues(initialFormValues);
    setSelectedDevice(data.devicesList.length ? data.devicesList[0] : "");
  };

  const afterSavingForm = (data: any): void => {
    setUncompletedCount(uncompletedCount - 1);
    if (data.createForm.id === "0") setErrors(["Hai già compilato il modulo per questo turno"]);
  };

  const loadFormData = (data: any): any => {
    if (data.pendingFormDetails.length) {
      setFormValues(adaptFormToBeLoaded(data.pendingFormDetails));
    }
  };

  const [getDevicesList, queryDevicesList] = useLazyQuery(QUERY_DEVICES_LIST, { onCompleted: initializeForm, fetchPolicy: "no-cache" });

  const [getShiftsList, queryShiftsList] = useLazyQuery(QUERY_SHIFTS_LIST, { fetchPolicy: "no-cache" });

  const [getMaterialsList, queryMaterialsList] = useLazyQuery(QUERY_MATERIALS_LIST, { fetchPolicy: "no-cache" });

  const [getUsersList, queryUsersList] = useLazyQuery(QUERY_USERS_LIST, { fetchPolicy: "no-cache" });

  const [getPendingFormDetails, queryPendingFormDetails] = useLazyQuery(QUERY_PENDING_FORM_DETAILS, { onCompleted: loadFormData, fetchPolicy: "no-cache" });

  const [uncompletedCount, setUncompletedCount] = useState<number>(-1);

  const [createForm] = useMutation(MUTATION_CREATE_FROM, { onCompleted: afterSavingForm });

  const [errors, setErrors] = useState<Array<string>>([]);

  const [isShowSuccessVisible, setIsShowSuccessVisible] = useState<number>(0);

  const [isDefinitive, setIsDefinitive] = useState<boolean>(false);

  useEffect(() => {
    fetchData();
    /* eslint-disable-next-line */
  }, []);

  useEffect(() => {
    if (uncompletedCount === 0 && isDefinitive && !errors.length) {
      initializeForm(queryDevicesList.data);
      setIsShowSuccessVisible(1);
      setIsDefinitive(false);
      window.scrollTo(0, 0);
      setTimeout((): void => setIsShowSuccessVisible(0), 2000);
    } else if (uncompletedCount === 0 && !isDefinitive && !errors.length) {
      setIsShowSuccessVisible(2);
      window.scrollTo(0, 0);
      setTimeout((): void => setIsShowSuccessVisible(0), 2000);
    }
  }, [uncompletedCount, isDefinitive, queryDevicesList.data, errors]);

  const fetchData = async (): Promise<void> => {
    getDevicesList();
    getShiftsList();
    getMaterialsList();
    getPendingFormDetails();
    getUsersList();
  };

  const onSelectDevice = (device: string): void => setSelectedDevice(device);

  const onClickSaveAsDraft = (): void => {
    setIsDefinitive(false);
    setUncompletedCount(queryDevicesList.data.devicesList.length);
    queryDevicesList.data.devicesList.forEach((device: string) => {
      const adaptedFormValues = adaptFormValuesToBeSent(device, formValues, 1);
      createForm({ variables: { ...adaptedFormValues } });
    });
  };

  const onClickSaveAsDefinitive = (): void => {
    const errorsAfterValidation = validateFormValues(formValues);
    setErrors(errorsAfterValidation);
    setIsDefinitive(true);
    if (!errorsAfterValidation.length) {
      setUncompletedCount(queryDevicesList.data.devicesList.length);
      queryDevicesList.data.devicesList.forEach((device: string) => {
        const adaptedFormValues = adaptFormValuesToBeSent(device, formValues, 2);
        createForm({ variables: { ...adaptedFormValues } });
      });
    }
  };

  return (
    <FormComponent
      queryDevicesList={queryDevicesList}
      queryShiftsList={queryShiftsList}
      queryMaterialsList={queryMaterialsList}
      queryUsersList={queryUsersList}
      formValues={formValues}
      setFormValues={setFormValues}
      onSelectDevice={onSelectDevice}
      selectedDevice={selectedDevice}
      onClickSaveAsDraft={onClickSaveAsDraft}
      onClickSaveAsDefinitive={onClickSaveAsDefinitive}
      errors={errors}
      isShowSuccessVisible={isShowSuccessVisible}
      queryPendingFormDetails={queryPendingFormDetails}
    />
  );
};

export default Form;
