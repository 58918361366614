import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import style from "./style.module.scss";
import { Box } from "@material-ui/core";

interface Props {
  onClickBackToLogin: any;
}

const Form = (props: Props): JSX.Element => {
  return (
    <div className={style.wrapper}>
      <div>
        <Box mb={2}>
          <Typography component="h1" variant="h5">
            Oooops, qualcosa &egrave; andato storto...
          </Typography>
        </Box>
        <Box mb={4}>
          <Typography component="p">
            Si &egrave; verificato un errore, prova ad effettuare nuovamente il login.
          </Typography>
        </Box>
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          onClick={props.onClickBackToLogin}
          size="large"
        >
          Torna al login
        </Button>
      </div>
    </div>
  );
};

export default Form;
