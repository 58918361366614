import React, { useEffect, useState } from "react";
import Main from "./components/main";
import { QUERY_MATERIALS_LIST, MUTATION_CREATE_MATERIAL, MUTATION_UPDATE_MATERIAL } from "../../queries";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";

export interface FormValues {
  name: string;
}

const validateFormValues = (formValues: FormValues | undefined): boolean => {
  if (!formValues?.name) return false;
  return true;
};

const Materials = (): JSX.Element => {
  const [getMaterialsList, queryMaterialsList] = useLazyQuery(QUERY_MATERIALS_LIST, { fetchPolicy: "no-cache" });
  const [formValues, setFormValues] = useState<FormValues>({
    name: "",
  });

  const [onEditMaterialId, setOnEditMaterialId] = useState<string>("");

  const afterSavingMaterial = (): void => {
    setFormValues({
      name: "",
    });
  };

  const fetchData = async (): Promise<void> => {
    getMaterialsList();
  };
  const [createMaterial] = useMutation(MUTATION_CREATE_MATERIAL, { onCompleted: afterSavingMaterial });
  const [updateMaterial] = useMutation(MUTATION_UPDATE_MATERIAL, { onCompleted: afterSavingMaterial });

  useEffect(() => {
    fetchData();
    /* eslint-disable-next-line */
  }, []);

  const onClickSubmit = (): void => {
    if (onEditMaterialId) {
      updateMaterial({ variables: { ...formValues, id: onEditMaterialId } });
    } else {
      createMaterial({ variables: { ...formValues } });
    }
    fetchData();
    setOnEditMaterialId("");
  };

  const onClickEditMaterial = (id: string): void => {
    setOnEditMaterialId(id);
    const selectedMaterial = queryMaterialsList.data.materialsList.find((material: any): boolean => material.id === id);
    setFormValues({ name: selectedMaterial.name });
  };

  const onClickReset = (): void => {
    setFormValues({
      name: "",
    });
    setOnEditMaterialId("");
  };

  return (
    <Main
      queryMaterialsList={queryMaterialsList}
      onClickSubmit={onClickSubmit}
      formValues={formValues}
      setFormValues={setFormValues}
      isSubmitButtonDisabled={!validateFormValues(formValues)}
      onClickEditMaterial={onClickEditMaterial}
      onEditMaterialId={onEditMaterialId}
      onClickReset={onClickReset}
    />
  );
};

export default Materials;
