import { ApolloClient, HttpLink, ApolloLink, InMemoryCache, concat } from "@apollo/client";

const httpLink = new HttpLink({ uri: `${process.env.REACT_APP_BACKEND_URL}graphql` });

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: `Bearer ${localStorage.getItem("accessToken")}` || null,
    },
  });

  return forward(operation);
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, httpLink),
});

export default client;
