import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from "@material-ui/icons/Settings";
// import LayersIcon from "@material-ui/icons/Layers";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import CheckCircle from "@material-ui/icons/InsertDriveFileRounded";
// import ReceiptIcon from "@material-ui/icons/Receipt";
// import PeopleIcon from "@material-ui/icons/People";
// import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";
import TableChartIcon from "@material-ui/icons/TableChart";
import TableChartOutlinedIcon from "@material-ui/icons/TableChartOutlined";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import MenuIcon from "@material-ui/icons/Menu";
import RouterIcon from "@material-ui/icons/Router";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import logoLight from "../../../../assets/images/logo_light.png";
import style from "./style.module.scss";
import ShowHide from "../../../../components/show-hide";
import { isUserAuthorized } from "../../../../utils";
import MUILink from "@material-ui/core/Link";
import PublishIcon from "@material-ui/icons/Publish";

const drawerWidth = 70;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
  },
  title: {
    flexGrow: 1,
  },
  drawer: {
    [theme.breakpoints.up("xl")]: {
      width: drawerWidth,
      flexShrink: 0,
    },

  },
  appBar: {
    [theme.breakpoints.up("xl")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("xl")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
}));

interface Props {
  children: any;
  onClickLogout: any;
  onClickSettings: any;
}

const Layout = (props: Props): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = (): void => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <div className={style.menuItems}>
        <ShowHide isVisible={isUserAuthorized(1)}>
          <Link to="/">
            <div className={window.location.pathname === "/" ? style.linkActive : style.linkInactive}>
              <DashboardIcon />
            </div>
          </Link>
        </ShowHide>
        <ShowHide isVisible={isUserAuthorized(1)}>
          <Link to="/devices">
            <div className={window.location.pathname === "/devices" ? style.linkActive : style.linkInactive}>
              <RouterIcon />
            </div>
          </Link>
        </ShowHide>
        <ShowHide isVisible={isUserAuthorized(1)}>
          <Link to="/jobs">
            <div className={window.location.pathname === "/jobs" ? style.linkActive : style.linkInactive}>
              <CheckCircle />
            </div>
          </Link>
        </ShowHide>
        <div className={style.separator} />
        <ShowHide isVisible={isUserAuthorized(1)}>
          <Link to="/raw-data">
            <div className={window.location.pathname === "/raw-data" ? style.linkActive : style.linkInactive}>
              <TableChartIcon />
            </div>
          </Link>
        </ShowHide>
        <ShowHide isVisible={isUserAuthorized(1)}>
          <Link to="/live-data">
            <div className={window.location.pathname === "/live-data" ? style.linkActive : style.linkInactive}>
              <TableChartOutlinedIcon />
            </div>
          </Link>
        </ShowHide>
        <ShowHide isVisible={isUserAuthorized(1)}>
          <Link to="/import-deltas">
            <div className={window.location.pathname === "/import-deltas" ? style.linkActive : style.linkInactive}>
              <PublishIcon />
            </div>
          </Link>
        </ShowHide>
        {/* <ShowHide isVisible={isUserAuthorized(1, true)}>
          <Link to="/forms">
            <ListItem button className={window.location.pathname === "/forms" || window.location.pathname === "/form" ? style.linkActive : style.linkInactive}>
              <ListItemIcon><ReceiptIcon /></ListItemIcon>
              <ListItemText primary="Moduli" />
            </ListItem>
          </Link>
        </ShowHide> */}
        {/* <ShowHide isVisible={isUserAuthorized(1, true)}>
          <Link to="/users">
            <ListItem button className={window.location.pathname === "/users" ? style.linkActive : style.linkInactive}>
              <ListItemIcon><PeopleIcon /></ListItemIcon>
              <ListItemText primary="Utenti" />
            </ListItem>
          </Link>
        </ShowHide> */}
        {/* <ShowHide isVisible={isUserAuthorized(1, true)}>
          <Link to="/materials">
            <ListItem button className={window.location.pathname === "/materials" ? style.linkActive : style.linkInactive}>
              <ListItemIcon><LayersIcon /></ListItemIcon>
              <ListItemText primary="Materiali" />
            </ListItem>
          </Link>
        </ShowHide> */}
        {/* <ShowHide isVisible={isUserAuthorized(1, true)}>
          <Link to="/settings">
            <ListItem button className={window.location.pathname === "/settings" ? style.linkActive : style.linkInactive}>
              <ListItemIcon><SettingsIcon /></ListItemIcon>
              <ListItemText primary="Impostazioni" />
            </ListItem>
          </Link>
        </ShowHide> */}
        {/* <ShowHide isVisible={isUserAuthorized(2)}>
          <Link to="/plc">
            <ListItem button className={window.location.pathname === "/plc" ? style.linkActive : style.linkInactive}>
              <ListItemIcon><DesktopWindowsIcon /></ListItemIcon>
              <ListItemText primary="Pannello" />
            </ListItem>
          </Link>
        </ShowHide> */}
        {/* <ShowHide isVisible={isUserAuthorized(2, true)}>
          <Link to="/form">
            <ListItem button className={window.location.pathname === "/form" ? style.linkActive : style.linkInactive}>
              <ListItemIcon><ReceiptIcon /></ListItemIcon>
              <ListItemText primary="Modulo" />
            </ListItem>
          </Link>
        </ShowHide> */}
      </div>
    </div>
  );

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <img className={style.logo} src={logoLight} alt="Terni Polimeri" />
            <Typography variant="h6" noWrap className={classes.title}>
              Terni Polimeri
            </Typography>
            <div className={style.actionsWrapper}>
              <div>
                <IconButton onClick={props.onClickSettings} aria-label="display more actions" edge="end" color="inherit">
                  <SettingsIcon />
                </IconButton>
              </div>
              <div>
                <IconButton onClick={props.onClickLogout} aria-label="display more actions" edge="end" color="inherit">
                  <ExitToAppIcon />
                </IconButton>
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer} aria-label="mailbox folders">
          <Hidden xlUp implementation="css">
            <Drawer
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden lgDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={style.contentWrapper}>
          <div>
            {props.children}
          </div>
          <div>
            <MUILink color="inherit" href="https://ternipolimeri.it/">
              © Terni Polimeri S.R.L. - P.I. 07607711004
            </MUILink>
          </div>
        </main>
      </div>
    </>
  );
};

export default Layout;
