import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import style from "./style.module.scss";
import { FormValues } from "../";

interface Props {
  formValues: FormValues;
  onChangeForm: any;
  loginError: boolean;
  onClickLogin: any;
}

const Form = (props: Props): JSX.Element => {
  return (
    <div className={style.wrapper}>
      <div>
        <Typography component="h1" variant="h5">
          Terni Polimeri
        </Typography>
        <TextField
          error={props.loginError}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Username"
          name="email"
          autoFocus
          value={props.formValues.email}
          onChange={props.onChangeForm}
        />
        <TextField
          error={props.loginError}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={props.formValues.password}
          onChange={props.onChangeForm}
        />
        <Box mt={1}>
          {
            props.loginError ?
              (
                <Box mb={2}>
                  <Typography align="left" color="error" variant="caption">
                    * Username o password non corretti
                  </Typography>
                </Box>
              ) : (
                <></>
              )
          }
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={props.onClickLogin}
            size="large"
          >
            Accedi
          </Button>
        </Box>
        <Box mt={4}>
          <Typography variant="body2" color="textSecondary" align="center">
            <Link color="inherit" href="https://ternipolimeri.it/">
              © Terni Polimeri S.R.L. - P.I. 07607711004
            </Link>
          </Typography>
        </Box>
      </div>
    </div>
  );
};

export default Form;
