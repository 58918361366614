import React from "react";
import { Bar } from "react-chartjs-2";
import { formatNumber } from "../../utils";

interface Props {
  data: any;
  width: number;
  height: number;
  maxValue?: number;
}

const BarChart = (props: Props): JSX.Element => {
  return (
    <Bar
      data={props.data}
      width={props.width}
      height={props.height}
      options={
        {
          maintainAspectRatio: true,
          legend: {
            display: false,
          },
          scales: {
            yAxes: [{
              ticks: {
                callback: (value: any, index: number): any => index === 0 ? undefined : value,
                beginAtZero: true,
                min: 0,
                max: props.maxValue ? props.maxValue * 1.1 : undefined,
              },
            }],
          },
          tooltips: {
            displayColors: false,
            callbacks: {
              label: (item: any): string => formatNumber(item.yLabel),
            },
          },
        }
      }
    />
  );
};

export default BarChart;
