/* eslint-disable max-len */

import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import PieChart from "../../../../components/pie-chart";
import LineChart from "../../../../components/line-chart";
import BarChart from "../../../../components/bar-chart";
import { getColorsList, getDeviceColor, getDeviceShortName, getColumDescription, formatNumber } from "../../../../utils";
import style from "./style.module.scss";
import Input from "../../../../components/input";
import { Button } from "@material-ui/core";
import { ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon } from "@material-ui/icons";

interface Props {
  queryDevicesList: any;
  queryDeltasPerDateList: any;
  queryFormsPerDateList: any;
  queryShiftsList: any;
  onChangeDate: any;
  date: string;
  onClickPreviousDate: any;
  onClickNextDate: any;
}

const getProducts = (device: string, shift: string, data: string): Set<string> => {
  const parsedData = JSON.parse(data);
  if (parsedData[device][shift].length) {
    return new Set(parsedData[device][shift].map((item: any): string => item.product));
  }
  return new Set([]);
};

const getPieDatasetLabels = (device: string, shift: string, data: string): Array<string> => {
  const parsedData = JSON.parse(data);
  if (parsedData[device][shift].length) {
    return Object.keys(JSON.parse(parsedData[device][shift][0].data))
      .sort((a: string, b: string): any => a > b)
      .map((key: string): string => getColumDescription(key));
  }
  return [];
};

const getPieDatasetValues = (device: string, shift: string, data: string): Array<number> => {
  const parsedData = JSON.parse(data);
  const result: any = [];
  if (parsedData[device][shift].length) {
    const keys = Object.keys(JSON.parse(parsedData[device][shift][0].data))
      .sort((a: string, b: string): any => a > b);
    let total = 0;
    keys.forEach((key: string): void => {
      total = 0;
      parsedData[device][shift].forEach((deltas: any): void => {
        const deltasData = JSON.parse(deltas.data);
        total += deltasData[key];
      });
      result.push(total);
    });
    return result;
  }
  return [];
};

const getDeltasSum = (data: any): number => {
  let result = 0;
  Object.keys(data).forEach((key: string): void => {
    result += data[key];
  });
  return result;
};

const getLineDatasetLabels = (shifts: Array<string>): Array<string> => {
  const result: Array<string> = [];
  shifts.forEach((shift: string): void => {
    const shiftData: Array<string> = shift.split(",");
    const shiftStart: number = parseInt(shiftData[0].substr(0, 2));
    const shiftEnd: number = parseInt(shiftData[1].substr(0, 2));
    if (shiftStart > shiftEnd) {
      for (let index = shiftStart + 1; index < 24; index++) {
        if (!result.length) result.push((index - 1).toString().padStart(2, "0") + ":00");
        result.push(index.toString().padStart(2, "0") + ":00");
      }
      result.push("00:00");
      for (let index = 1; index <= shiftEnd; index++) {
        if (!result.length) result.push((index - 1).toString().padStart(2, "0") + ":00");
        result.push(index.toString().padStart(2, "0") + ":00");
      }
    } else {
      for (let index = shiftStart + 1; index <= shiftEnd; index++) {
        if (!result.length) result.push((index - 1).toString().padStart(2, "0") + ":00");
        result.push(index.toString().padStart(2, "0") + ":00");
      }
    }
  });
  return result.slice(0, result.length - 1);
};

const getLineDatasetValues = (device: string, shifts: Array<string>, data: string): Array<any> => {
  const parsedData = JSON.parse(data);
  const result: Array<any> = [];
  const labels: Array<string> = getLineDatasetLabels(shifts);
  const shiftValues: Array<any> = [];
  shifts.forEach((shift: string): void => {
    if (parsedData[device][shift].length) {
      parsedData[device][shift]
        .forEach((delta: any): void => {
          shiftValues.push({ timestamp: delta.timestamp, value: getDeltasSum(JSON.parse(delta.data)) });
        });
    }
  });
  labels.forEach((label: string): void => {
    let total = 0;
    const hour: string = label.substring(0, 2);
    shiftValues.forEach((timestampValue: any): void => {
      if (timestampValue.timestamp.substring(11, 13) === hour) {
        total += timestampValue.value;
      }
    });
    result.push(total);
  });
  return result;
};

const getLineDatasetMaxValue = (device: string, data: string, shifts: Array<string>): number => {
  let result = 0;
  const shiftData = getLineDatasetValues(device, shifts, data);
  shiftData.forEach((delta: string): void => {
    if (parseFloat(delta) > result) result = parseFloat(delta);
  });
  return result;
};

const getBarDatasetLabels = (shifts: Array<string>): Array<string> => {
  return shifts;
};

const getBarDatasetValuesFromDeltas = (device: string, shifts: Array<string>, data: string): Array<number> => {
  const parsedData = JSON.parse(data);
  const result: any = [];
  shifts.forEach((shift: string): void => {
    let total = 0;
    if (parsedData[device][shift].length) {
      const keys = Object.keys(JSON.parse(parsedData[device][shift][0].data));
      keys.forEach((key: string): void => {
        parsedData[device][shift].forEach((deltas: any): void => {
          const deltasData = JSON.parse(deltas.data);
          total += deltasData[key];
        });
      });
    }
    result.push(total);
  });
  return result;
};

const getBarDatasetValuesFromForms = (device: string, shifts: Array<string>, data: Array<any>): Array<number> => {
  const result: any = [];
  shifts.forEach((shift: string): void => {
    const forms: Array<any> = data.filter((form: any): boolean => form.device === device && form.shift === shift);
    let total = 0;
    forms.forEach((form: any): void => {
      total += form.materials.reduce((total: number, item: any): number => total + item.quantity_produced, 0);
    });
    result.push(total);
  });
  return result;
};

const getBarDatasetMaxValue = (device: string, deltasData: string, formsData: Array<any>, shifts: Array<string>): number => {
  const deltasBars = getBarDatasetValuesFromDeltas(device, shifts, deltasData);
  const formsBars = getBarDatasetValuesFromForms(device, shifts, formsData);
  const allBars: Array<number> = [...deltasBars, ...formsBars];
  return Math.max(...allBars);
};

const getResumeMaterialsQuantities = (device: string, shift: string, forms: Array<any>): Array<any> => {
  const result: Array<any> = [];
  forms.forEach((form: any): void => {
    if (form.device === device && form.shift === shift) {
      form.materials.forEach((material: any): void => {
        result.push(material);
      });
    }
  });
  return result;
};

const Main = (props: Props): JSX.Element => {
  const onClickExport = (): void => {
    const saveFile = (rows: any, filename: any): any => {
      const blob = new Blob([rows], { type: "text/plain;charset=utf-8" });
      const URL_ = (window.URL || window.webkitURL);
      const fileURL = URL_.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("href", fileURL);
      a.setAttribute("download", filename);
      a.click();
      a.remove();
      setTimeout(function () {
        URL_.revokeObjectURL(fileURL);
      }, 60000);
    };
    let rows = "'Tempo';'Delco';'Prodotto';'Talco';'Master';'Macinato';'Additivi';'Carbonato';'Perossido';'Fibra';\n";
    props.queryDevicesList.data.devicesList.forEach((device: string): void => {
      Object.keys(JSON.parse(props.queryDeltasPerDateList.data.deltasPerDateList.data)[device]).forEach((shift: any): void => {
        const items = JSON.parse(props.queryDeltasPerDateList.data.deltasPerDateList.data)[device][shift];
        items.forEach((item: any): void => {
          rows += `'${item.timestamp}';'${device}';'${item.product}';`;
          const data = JSON.parse(item.data);
          Object.keys(data).forEach((key: string): void => {
            rows += `'${data[key]}';`;
          });
          rows += "\n";
        });
      });
    });
    const splittedDate = props.date.split("-");
    saveFile(rows, `Produzione_${splittedDate[2]}-${splittedDate[1]}-${splittedDate[0].slice(-2)}.csv`);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <div className={style.dateCardWrapper}>
          <Paper>
            <div className={style.dateWrapper}>
              <div>
                <Button variant="contained" color="primary" onClick={props.onClickPreviousDate}><ArrowBackIcon /></Button>
              </div>
              <div>
                <Input type="date" value={props.date} onChange={(event: any): void => props.onChangeDate(event.target.value)} />
              </div>
              <div>
                <Button variant="contained" color="primary" onClick={props.onClickNextDate}><ArrowForwardIcon /></Button>
              </div>
              <div className={style.exportWrapper}>
                <Button variant="contained" color="primary" onClick={onClickExport}>Esporta</Button>
              </div>
            </div>
          </Paper>
        </div>
        {
          (
            props.queryDevicesList.loading ||
            !props.queryDevicesList.data ||
            props.queryDeltasPerDateList.loading ||
            !props.queryDeltasPerDateList.data ||
            props.queryShiftsList.loading ||
            !props.queryShiftsList.data ||
            props.queryFormsPerDateList.loading ||
            !props.queryFormsPerDateList.data
          ) ? <CircularProgress /> :
            (
              <Paper>
                <div className={style.devicesColumns}>
                  {
                    props.queryDevicesList.data.devicesList.map((device: string): JSX.Element => (
                      <div className={style.wrapper} key={`device_column_${device}`}>
                        <span className={style.deviceName} style={{ backgroundColor: getDeviceColor(device) }}>{getDeviceShortName(device)}</span>
                        <span className={style.deviceLabel} style={{ color: getDeviceColor(device) }}>Delco {getDeviceShortName(device)}</span>
                        <div className={style.shiftProductsWrapper}>
                          {
                            props.queryShiftsList.data.shiftsList.map((shift: string, index: number): JSX.Element => {
                              const products: Set<string> = getProducts(device, shift, props.queryDeltasPerDateList.data.deltasPerDateList.data);
                              return (
                                <div key={`shift_products_${shift}`}>
                                  <b className={style.shiftLabel}>Turno {index + 1}</b>
                                  {
                                    Array.from(products).map((product: string): JSX.Element => (
                                      <span className={style.productItem} key={`shift_product_${product}`}>
                                        {product}
                                      </span>
                                    ))
                                  }
                                </div>
                              );
                            })
                          }
                        </div>
                        <div className={style.shiftPieWrapper}>
                          {
                            props.queryShiftsList.data.shiftsList.map((shift: string): JSX.Element => {
                              const labels: Array<string> = getPieDatasetLabels(device, shift, props.queryDeltasPerDateList.data.deltasPerDateList.data);
                              return (
                                <div key={`shift_pie_${device}_${shift}`}>
                                  <PieChart
                                    data={
                                      {
                                        labels: labels,
                                        datasets: [
                                          {
                                            backgroundColor: getColorsList(labels.length),
                                            data: getPieDatasetValues(device, shift, props.queryDeltasPerDateList.data.deltasPerDateList.data),
                                          },
                                        ],
                                      }
                                    }
                                    width={100}
                                    height={100}
                                  />
                                </div>
                              );
                            })
                          }
                        </div>
                        <div className={style.lineChartWrapper}>
                          <LineChart
                            data={
                              {
                                labels: getLineDatasetLabels(props.queryShiftsList.data.shiftsList),
                                datasets: [
                                  {
                                    lineTension: 0.2,
                                    borderColor: `${getDeviceColor(device)}ff`,
                                    backgroundColor: `${getDeviceColor(device)}77`,
                                    data: getLineDatasetValues(device, props.queryShiftsList.data.shiftsList, props.queryDeltasPerDateList.data.deltasPerDateList.data),
                                  },
                                ],
                              }
                            }
                            width={100}
                            height={30}
                            maxValue={getLineDatasetMaxValue(device, props.queryDeltasPerDateList.data.deltasPerDateList.data, props.queryShiftsList.data.shiftsList)}
                          />
                        </div>
                        <div className={style.barChartWrapper} style={{ display: "none" }}>
                          <BarChart
                            data={
                              {
                                labels: getBarDatasetLabels(props.queryShiftsList.data.shiftsList),
                                datasets: [
                                  {
                                    label: `bar_dataset_deltas_${device}`,
                                    borderWidth: 2,
                                    borderColor: `${getDeviceColor(device)}ff`,
                                    backgroundColor: `${getDeviceColor(device)}77`,
                                    data: getBarDatasetValuesFromDeltas(device, props.queryShiftsList.data.shiftsList, props.queryDeltasPerDateList.data.deltasPerDateList.data),
                                  },
                                  {
                                    label: `bar_dataset_forms_${device}`,
                                    borderWidth: 2,
                                    borderColor: `${getDeviceColor(device)}55`,
                                    backgroundColor: `${getDeviceColor(device)}33`,
                                    data: getBarDatasetValuesFromForms(device, props.queryShiftsList.data.shiftsList, props.queryFormsPerDateList.data.formsPerDateList),
                                  },
                                ],
                              }
                            }
                            width={90}
                            height={25}
                            maxValue={getBarDatasetMaxValue(device, props.queryDeltasPerDateList.data.deltasPerDateList.data, props.queryFormsPerDateList.data.formsPerDateList, props.queryShiftsList.data.shiftsList)}
                          />
                        </div>
                        <div className={style.resumeWrapper}>
                          {
                            props.queryShiftsList.data.shiftsList.map((shift: string): JSX.Element => (
                              <div key={`shift_resume_${shift}`}>
                                {
                                  getResumeMaterialsQuantities(device, shift, props.queryFormsPerDateList.data.formsPerDateList).map((item: any): JSX.Element => (
                                    <div key={`shift_resume_material_${item.material.id}`}>
                                      <b>{item.material.name}</b>
                                      <span>{formatNumber(item.quantity_produced)}{item.quantity_purged && ` - ${formatNumber(item.quantity_purged)}`}</span>
                                    </div>
                                  ))
                                }
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    ))
                  }
                </div>
              </Paper>
            )
        }
      </Grid>
    </Grid>
  );
};

export default Main;
