import React, { useEffect, useState } from "react";
import Main from "./components/main";
import { QUERY_USERS_LIST, MUTATION_CREATE_USER, MUTATION_UPDATE_USER, MUTATION_UPDATE_USER_STATUS } from "../../queries";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";

export interface FormValues {
  name: string;
  email: string;
  password: string;
}

const validateFormValues = (formValues: FormValues | undefined): boolean => {

  if(!formValues?.email) return false;
  if(!formValues?.name) return false;
  if(!formValues?.password) return false;

  return true;
};

const Users = (): JSX.Element => {
  const [getUsersList, queryUsersList] = useLazyQuery(QUERY_USERS_LIST, { fetchPolicy: "no-cache" });
  const [formValues, setFormValues] = useState<FormValues>({
    name: "",
    email: "",
    password: "",
  });

  const [onEditUserId, setOnEditUserId] = useState<string>("");

  const afterSavingUser = (): void =>{
    setFormValues({
      name: "",
      email: "",
      password: "",
    });
  };

  const fetchData = async (): Promise<void> => {
    getUsersList();
  };
  const [createUser] = useMutation(MUTATION_CREATE_USER, { onCompleted: afterSavingUser });
  const [updateUser] = useMutation(MUTATION_UPDATE_USER, { onCompleted: afterSavingUser });
  const [updateUserStatus] = useMutation(MUTATION_UPDATE_USER_STATUS, { onCompleted: fetchData });

  useEffect(() => {
    fetchData();
    /* eslint-disable-next-line */
  }, []);

  const onClickSubmit = (): void =>{
    if(onEditUserId){
      updateUser({ variables: { ...formValues, type: 2, id: onEditUserId } });
    }else{
      createUser({ variables: { ...formValues, status: 1, type: 2 } });
    }
    fetchData();
    setOnEditUserId("");
  };

  const onClickEditUser = (id: string): void =>{
    setOnEditUserId(id);
    const selectedUser = queryUsersList.data.usersList.find((user: any): boolean => user.id === id);
    setFormValues({email: selectedUser.email, name: selectedUser.name, password: ""});
  };

  const onClickReset = (): void =>{
    setFormValues({
      name: "",
      email: "",
      password: "",
    });
    setOnEditUserId("");
  };

  const onClickUpdateUserStatus = (id: string, status: number): void =>{
    updateUserStatus({variables : {id, status}});
  };

  return (
    <Main
      queryUsersList={queryUsersList}
      onClickSubmit={onClickSubmit}
      formValues={formValues}
      setFormValues={setFormValues}
      isSubmitButtonDisabled={!validateFormValues(formValues)}
      onClickEditUser={onClickEditUser}
      onEditUserId={onEditUserId}
      onClickReset={onClickReset}
      onClickUpdateUserStatus={onClickUpdateUserStatus}
    />
  );
};

export default Users;
