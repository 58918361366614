/* eslint-disable @typescript-eslint/no-use-before-define */

import React, { useEffect, useState } from "react";
import Main from "./components/main";
import { QUERY_DEVICE_JOBS_LIST, MUTATION_DELETE_DEVICE_JOB, MUTATION_START_WORK, MUTATION_CREATE_DEVICE_JOB, QUERY_DEVICE_SETTINGS, QUERY_DEVICES_LIST } from "../../queries";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";

const adaptFormValuesToBeSent = (fromValues: any, device: any): any => (
  {
    device,
    description: fromValues.description,
    quantity: fromValues.quantity,
    note: "",
    data: JSON.stringify(Object.keys(fromValues).filter((key: string) => key.startsWith("field_")).map((field: string) => ({ code: field.replace("field_", ""), value: fromValues[field] }))),
  }
);

const Jobs = (): JSX.Element => {

  const afterSavingDeviceJob = (): void => {
    setFormValues({});
  };

  const [getDevicesList, queryDevicesList] = useLazyQuery(QUERY_DEVICES_LIST, { fetchPolicy: "no-cache" });
  const [getDeviceSettings, queryDeviceSettings] = useLazyQuery(QUERY_DEVICE_SETTINGS, { fetchPolicy: "no-cache" });
  const [getDeviceJobsList, queryDeviceJobsList] = useLazyQuery(QUERY_DEVICE_JOBS_LIST, { fetchPolicy: "no-cache" });
  const [createDeviceJob] = useMutation(MUTATION_CREATE_DEVICE_JOB, { onCompleted: afterSavingDeviceJob });
  const [deleteDeviceJob] = useMutation(MUTATION_DELETE_DEVICE_JOB);
  const [startWork] = useMutation(MUTATION_START_WORK);
  const [formValues, setFormValues] = useState<any>({});
  const [isAddModalVisible, setIsAddModalVisible] = useState<boolean>(false);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState<boolean>(false);
  const [selectedDevice, setSelectedDevice] = useState<string>("");

  const onClickAddDeviceJob = (device: string): void => {
    setSelectedDevice(device);
    setIsAddModalVisible(true);
  };

  const onClickCancelAddDeviceJob = (): void => {
    setIsAddModalVisible(false);
    setSelectedDevice("");
    setFormValues({});
  };

  const onClickConform = (): void => {
    setIsConfirmModalVisible(false);
    startWork();
  };

  const onClickCancelConfirm = (): void => {
    setIsConfirmModalVisible(false);
  };

  const onClickSaveDeviceJob = (): void => {
    createDeviceJob({ variables: { ...adaptFormValuesToBeSent(formValues, selectedDevice), device: selectedDevice } });
    fetchData();
    setIsAddModalVisible(false);
    setSelectedDevice("");
    setFormValues({});
  };

  const onClickDeleteDeviceJob = (id: number): void => {
    deleteDeviceJob({ variables: { id } });
    fetchData();
  };

  const onClickStartWork = (): void => {
    setIsConfirmModalVisible(true);
  };

  const fetchData = (): void => {
    getDeviceJobsList();
    getDeviceSettings();
    getDevicesList();
  };

  useEffect(() => {
    fetchData();
    /* eslint-disable-next-line */
  }, []);

  return (
    <Main
      queryDeviceSettings={queryDeviceSettings}
      queryDeviceJobsList={queryDeviceJobsList}
      formValues={formValues}
      setFormValues={setFormValues}
      isAddModalVisible={isAddModalVisible}
      onClickAddDeviceJob={onClickAddDeviceJob}
      onClickCancelAddDeviceJob={onClickCancelAddDeviceJob}
      onClickSaveDeviceJob={onClickSaveDeviceJob}
      onClickDeleteDeviceJob={onClickDeleteDeviceJob}
      onClickStartWork={onClickStartWork}
      queryDevicesList={queryDevicesList}
      selectedDevice={selectedDevice}
      isConfirmModalVisible={isConfirmModalVisible}
      onClickConform={onClickConform}
      onClickCancelConfirm={onClickCancelConfirm}
      deleteDeviceJob={deleteDeviceJob}
    />
  );
};

export default Jobs;
