/* eslint-disable @typescript-eslint/no-use-before-define */

import React from "react";
import Main from "./components/main";
import { MUTATION_IMPORT_DELTAS } from "../../queries";
import { useMutation } from "@apollo/react-hooks";
import Papa from "papaparse";
import { SHITS_TIMES } from "../../utils";
import { parse, addDays, format } from "date-fns";

const getColumns = (row: any): string[] => {
  return Object.keys(row).filter((key: string): boolean => !["date", "shift", "total", "product"].includes(key));
};

const generateDeltaRecords = (device: string, row: any): any[] => {
  const result: any[] = [];
  const columns: any[] = getColumns(row);
  SHITS_TIMES[row["shift"]].forEach((time: string): void => {
    const data: any = {};
    columns.forEach((column: string): void => {
      data[column] = parseFloat((row[column] ? parseFloat(row["total"]) * parseFloat(row[column].replace(",", ".")) / 100 / SHITS_TIMES[row["shift"]].length : 0).toFixed(2));
    });
    if (parseInt(time.substring(0, 2)) < 6) {
      const inputDateString = row.date;
      const parsedDate = parse(inputDateString, "yyyy-MM-dd", new Date());
      const newDate = addDays(parsedDate, 1);
      const formattedDate = format(newDate, "yyyy-MM-dd");
      result.push({
        device,
        timestamp: `${formattedDate} ${time}`,
        data,
        product: row.product,
      });
    } else {
      result.push({
        device,
        timestamp: `${row.date} ${time}`,
        data,
        product: row.product,
      });
    }
  });
  return result;
};

const ImportDeltas = (): JSX.Element => {
  const [importDeltas] = useMutation(MUTATION_IMPORT_DELTAS);

  const onReceiveFileData = (file: File, fileData: string): void => {
    const onParseCsv = async (parsedData: any): Promise<void> => {
      let data: any[] = [];
      parsedData.data.forEach((row: any): void => {
        if (row.date) {
          const result = generateDeltaRecords(file.name.replace(".csv", ""), row);
          data = [...data, ...result];
        }
      });
      await importDeltas({ variables: { data: JSON.stringify(data) } });
      alert("Importazione completata.");
      window.location.reload();
    };
    Papa.parse(fileData, {
      header: true,
      complete: onParseCsv,
    });
  };

  return (
    <Main onReceiveFileData={onReceiveFileData} />
  );
};

export default ImportDeltas;
