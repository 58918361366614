import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import style from "./style.module.scss";

interface Props {
  children?: any;
}

const Layout = (props: Props): JSX.Element => {
  const location = useLocation();

  const [shouldRefresh, setShouldRefresh] = useState<boolean>(false);

  const fetchData = async (show: boolean): Promise<any> => {
    if (process.env.NODE_ENV === "production") {
      const versionResponse: any = await fetch("/version.json", { cache: "no-cache" });
      const versionJson: any = await versionResponse.json();
      if(show) {
        setShouldRefresh(versionJson.date !== localStorage.getItem("version"));
      }
      return versionJson.date;
    }
    return undefined;
  };

  useEffect((): void => {
    fetchData(true);
  }, [location]);

  const onClickRefresh = async (): Promise<void> => {
    const date = await fetchData(false);
    localStorage.setItem("version", date);
    window.location.reload();
  };

  return (
    <>
      {
        shouldRefresh ? (
          <div className={style.refreshWrapper} onClick={onClickRefresh}>
            <span>Premi qui per aggiornare alla nuova versione</span>
          </div>
        ) : <></>
      }
      {props.children}
    </>
  );
};

export default Layout;
