/* eslint-disable max-len */

import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import style from "./style.module.scss";
import Input from "../../../../components/input";
import { Button } from "@material-ui/core";

interface Props {
  date: any;
  device: any;
  getLogDeltasPerDateList: any;
  getLogSensorsPerDateList: any;
  onChangeDate: any;
  onChangeDevice: any;
  onClickDeltas: any;
  onClickSensors: any;
  type: any;
  queryLogDeltasPerDateList: any;
  queryLogSensorsPerDateList: any;
}

const getColumns = (): Array<string> => (
  [
    "066_KG_PROD_DOS_MACINATO",
    "067_KG_PROD_DOS_MASTER",
    "068_KG_PROD_DOS_ADDITIVI",
    "069_KG_PROD_DOS_CARBONATO",
    "070_KG_PROD_DOS_TALCO",
    "078_KG_PROD_DOS_PEROSSIDO",
  ]
);

const getShifts = (): Array<string> => (
  [
    "06:00,14:00",
    "14:00,22:00",
    "22:00,06:00",
  ]
);

const getComputedSensorsDataForSensors = (data: any, date: string, dataKey: string): any => {
  const result: any = {};
  if (data === undefined) return result;
  const shifts = getShifts();
  shifts.forEach((shift: string): void => {
    result[shift] = {};
    const parsedData = JSON.parse(data[dataKey].data);
    const rows = parsedData.filter((row: any): boolean => {
      const dateOnly: string = row.timestamp.substring(0, 10);
      const timeOnly: string = row.timestamp.substring(11, 16);
      if (shift === "06:00,14:00" && timeOnly >= "06:00" && timeOnly < "14:00" && dateOnly === date) {
        return true;
      } else if (shift === "14:00,22:00" && timeOnly >= "14:00" && timeOnly < "22:00" && dateOnly === date) {
        return true;
      } else if (shift === "22:00,06:00" && ((timeOnly >= "22:00" && dateOnly === date) || (timeOnly < "06:00" && dateOnly > date))) {
        return true;
      }
      return false;
    }).map((row: any): any => ({ ...row, data: JSON.parse(row.data) }));
    result[shift].rows = rows;
  });
  return result;
};

const getComputedSensorsDataForDeltas = (data: any, date: string, dataKey: string): any => {
  const result: any = {};
  if (data === undefined) return result;
  const shifts = getShifts();
  shifts.forEach((shift: string): void => {
    result[shift] = {};
    const parsedData = JSON.parse(data[dataKey].data);
    const rows = parsedData.filter((row: any): boolean => {
      const dateOnly: string = row.timestamp.substring(0, 10);
      const timeOnly: string = row.timestamp.substring(11, 16);
      if (shift === "06:00,14:00" && timeOnly >= "06:00" && timeOnly <= "14:00" && dateOnly === date) {
        return true;
      } else if (shift === "14:00,22:00" && timeOnly > "14:00" && timeOnly <= "22:00" && dateOnly === date) {
        return true;
      } else if (shift === "22:00,06:00" && ((timeOnly > "22:00" && dateOnly === date) || (timeOnly <= "06:00" && dateOnly > date))) {
        return true;
      }
      return false;
    }).map((row: any): any => ({ ...row, data: JSON.parse(row.data) }));
    result[shift].rows = rows;
  });
  return result;
};

const Main = (props: Props): JSX.Element => {
  let generalTotal = 0;
  return (
    <Grid container>
      <Grid item xs={12}>
        <div className={style.dateCardWrapper}>
          <Paper>
            <div className={style.dateWrapper}>
              <div>
                <Input type="date" value={props.date} onChange={(event: any): void => props.onChangeDate(event.target.value)} />
              </div>
              <div>
                <Input type="device" value={props.device} onChange={(event: any): void => props.onChangeDevice(event.target.value)} />
              </div>
              <div>
                <Button
                  disabled={!props.date || !props.device}
                  variant="contained"
                  color="primary"
                  onClick={props.onClickDeltas}
                >
                  Deltas
                </Button>
              </div>
              <div>
                <Button
                  disabled={!props.date || !props.device}
                  variant="contained"
                  color="primary"
                  onClick={props.onClickSensors}
                >
                  Sensors
                </Button>
              </div>
            </div>
          </Paper>
        </div>
        {
          props.type === "deltas" ?
            (
              <Paper>
                <span className={style.title}>Deltas</span>
                <div className={style.deltasWrapper}>
                  {
                    props.queryLogDeltasPerDateList.loading || !props.queryLogDeltasPerDateList.data
                      ? <CircularProgress /> :
                      (
                        <>
                          {
                            props.queryLogDeltasPerDateList.data.logDeltasPerDateList.data !== "[]" ? (
                              <table>
                                <thead>
                                  <tr>
                                    <td>Timestamp</td>
                                    {
                                      getColumns().map((column: string): JSX.Element => (
                                        <td key={"header_" + column}>{column}</td>
                                      ))
                                    }
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    getShifts().map((shift: string): JSX.Element => {
                                      const shiftComputedSensorData = getComputedSensorsDataForDeltas(props.queryLogDeltasPerDateList.data, props.date, "logDeltasPerDateList")[shift];
                                      shiftComputedSensorData.rows.forEach((row: any): void => {
                                        Object.keys(row.data).forEach((key: string): any => {
                                          if (getColumns().find((column: string): boolean => column === key)) {
                                            generalTotal += row.data[key];
                                          }
                                        });
                                      });
                                      return (
                                        <React.Fragment key={`shift_${shift}`}>
                                          <tr className={style.tableSectionHeader} >
                                            <td colSpan={getColumns().length + 1}>{shift}</td>
                                          </tr>
                                          {
                                            shiftComputedSensorData.rows.map((row: any): JSX.Element => {
                                              return (
                                                <tr key={`row_${row.id}`}>
                                                  <td>{row.timestamp}</td>
                                                  {
                                                    getColumns().map((column: string): JSX.Element => (
                                                      <td key={`cell_${row.id}_${column}`}>{row.data[column]}</td>
                                                    ))
                                                  }
                                                </tr>
                                              );
                                            })
                                          }
                                        </React.Fragment>
                                      );
                                    })
                                  }
                                  <tr className={style.tableSectionTotal}>
                                    <td colSpan={getColumns().length + 1}>{generalTotal}</td>
                                  </tr>
                                </tbody>
                              </table>
                            ) : <></>
                          }
                        </>
                      )
                  }
                </div>
              </Paper>
            ) : <></>
        }
        {
          props.type === "sensors" ? (
            <Paper>
              <span className={style.title}>Sensors</span>
              <div className={style.deltasWrapper}>
                {
                  props.queryLogSensorsPerDateList.loading || !props.queryLogSensorsPerDateList.data
                    ? <CircularProgress /> :
                    (
                      <>
                        {
                          props.queryLogSensorsPerDateList.data.logSensorsPerDateList.data !== "[]" ? (
                            <table>
                              <thead>
                                <tr>
                                  <td>TIMESTAMP</td>
                                  {
                                    getColumns().map((column: string): JSX.Element => (
                                      <td key={"header_" + column}>{column}</td>
                                    ))
                                  }
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  getShifts().map((shift: string): JSX.Element => {
                                    const shiftComputedSensorData = getComputedSensorsDataForSensors(props.queryLogSensorsPerDateList.data, props.date, "logSensorsPerDateList")[shift];
                                    let shiftTotal = 0;
                                    getColumns().forEach((column: string): void => {
                                      shiftTotal += shiftComputedSensorData.rows[shiftComputedSensorData.rows.length - 1].data[column] - shiftComputedSensorData.rows[0].data[column];
                                    });
                                    generalTotal += shiftTotal;
                                    return (
                                      <React.Fragment key={`shift_${shift}`}>
                                        <tr className={style.tableSectionHeader} >
                                          <td colSpan={getColumns().length + 1}>{shift}</td>
                                        </tr>
                                        {
                                          shiftComputedSensorData.rows.map((row: any): JSX.Element => {
                                            return (
                                              <tr key={`row_${row.id}`}>
                                                <td>{row.timestamp}</td>
                                                {
                                                  getColumns().map((column: string): JSX.Element => (
                                                    <td key={`cell_${row.id}_${column}`}>{row.data[column]}</td>
                                                  ))
                                                }
                                              </tr>
                                            );
                                          })
                                        }
                                        <tr className={style.tableSectionFooter}>
                                          <td />
                                          {
                                            getColumns().map((column: string): JSX.Element => (
                                              <td key={`total_${shift}_${column}`}>{shiftComputedSensorData.rows[shiftComputedSensorData.rows.length - 1].data[column] - shiftComputedSensorData.rows[0].data[column]}</td>
                                            ))
                                          }
                                        </tr>
                                        <tr className={style.tableSectionTotal}>
                                          <td colSpan={getColumns().length + 1}>{shiftTotal}</td>
                                        </tr>
                                      </React.Fragment>
                                    );
                                  })
                                }
                                <tr className={style.tableSectionTotal}>
                                  <td colSpan={getColumns().length + 1}>{generalTotal}</td>
                                </tr>
                              </tbody>
                            </table>
                          ) : <></>
                        }
                      </>
                    )
                }
              </div>
            </Paper>
          ) : <></>
        }
      </Grid>
    </Grid >
  );
};

export default Main;
