/* eslint-disable @typescript-eslint/no-use-before-define */

import React, { useState } from "react";
import Main from "./components/main";
import { QUERY_LOG_DELTAS_PER_DATE_LIST, QUERY_LOG_SENSORS_PER_DATE_LIST } from "../../queries";
import { useLazyQuery } from "@apollo/react-hooks";

const Log = (): JSX.Element => {
  const [date, setDate] = useState<string>("");
  const [device, setDevice] = useState<string>("");
  const [type, setType] = useState<string | undefined>(undefined);

  const [getLogDeltasPerDateList, queryLogDeltasPerDateList] = useLazyQuery(QUERY_LOG_DELTAS_PER_DATE_LIST, { variables: { date: `${date} 00:00:00`, device }});
  const [getLogSensorsPerDateList, queryLogSensorsPerDateList] = useLazyQuery(QUERY_LOG_SENSORS_PER_DATE_LIST, { variables: { date: `${date} 00:00:00`, device }});

  const onChangeDate = (date: string): void => setDate(date);
  const onChangeDevice = (date: string): void => setDevice(date);

  const onClickDeltas = (): void => {
    getLogDeltasPerDateList();
    setType("deltas");
  };

  const onClickSensors = (): void => {
    getLogSensorsPerDateList();
    setType("sensors");
  };

  return (
    <Main
      date={date}
      device={device}
      getLogDeltasPerDateList={getLogDeltasPerDateList}
      getLogSensorsPerDateList={getLogSensorsPerDateList}
      onChangeDate={onChangeDate}
      onChangeDevice={onChangeDevice}
      onClickDeltas={onClickDeltas}
      onClickSensors={onClickSensors}
      type={type}
      queryLogDeltasPerDateList={queryLogDeltasPerDateList}
      queryLogSensorsPerDateList={queryLogSensorsPerDateList}
    />
  );
};

export default Log;
