import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import style from "./style.module.scss";
import { withStyles } from "@material-ui/core/styles";
import { getDayOfWeek, getDeviceShortName, getRange, formatNumber, getCurrentDate } from "../../../../utils";
import { Lens as LensIcon } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import { ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon } from "@material-ui/icons";

interface Props {
  queryDevicesList: any;
  queryFormsPerDateRangeList: any;
  queryShiftsList: any;
  onOpenTooltip: any;
  onCloseTooltip: any;
  selectedDay: string | undefined;
  range: any;
  onClickPreviousMonth: any;
  onClickNextMonth: any;
}

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    maxWidth: 500,
    fontSize: "0.8rem",
    backgroundColor: "#ffffff",
    boxShadow: "0 2px 8px 0 #00000033",
    color: "#222222",
    padding: "1rem",
  },
  arrow: {
    color: "#ffffff",
  },
}))(Tooltip);

interface TooltipContentProps {
  date: string;
  devices: Array<any>;
  shifts: Array<any>;
  dayShiftsData: any;
  onCloseTooltip: any;
}

const TooltipContent = (props: TooltipContentProps): JSX.Element => {
  return (
    <>
      <table className={style.detailsTable}>
        <tbody>
          <tr>
            <td>
              <span className={style.detailsLabel}>Delco</span>
            </td>
            {
              props.shifts.map((shift: string, index: number): JSX.Element => (
                <td key={`shift_details_label_${shift}`}>
                  <span className={style.detailsLabelCenter}>Turno {index + 1}</span>
                </td>
              ))
            }
          </tr>
          {
            props.devices.map((device: string): JSX.Element => (
              <tr key={`device_details_row_${device}`}>
                <td>
                  <span className={style.detailsLabel}>Delco {getDeviceShortName(device)}</span>
                </td>
                {
                  props.shifts.map((shift: string): JSX.Element => (
                    <td key={`shift_details_label_${shift}`}>
                      <span className={style.detailsValue} key={`shift_details_row_${shift}`}>{formatNumber(props.dayShiftsData[device][shift].total)}</span>
                    </td>
                  ))
                }
              </tr>
            ))
          }
        </tbody>
      </table>
      <div className={style.detailsActions}>
        <span className={style.detailsClose} onClick={props.onCloseTooltip}>Chiudi</span>
      </div>
    </>
  );
};

const getDayShiftsData = (devices: Array<string>, day: string, data: any, shifts: Array<string>): any => {
  const result: any = {};
  devices.forEach((device: string): void => {
    result[device] = {};
    shifts.forEach((shift: string): void => {
      result[device][shift] = {
        total: data
          .find((item: any) => item.date === day && item.device === device && item.shift === shift)?.materials
          .reduce((total: number, item: any): number => total + item.quantity_produced, 0) ?? 0,
      };
    });
  });
  return result;
};

const getDayTotal = (shifts: any): number => {
  let result = 0;
  Object.keys(shifts).forEach((shift: any) => {
    result += parseFloat(shifts[shift].total);
  });
  return result;
};

const getDaysCountInMonth = (date: string): number => {
  const year = parseInt(date.substr(0, 4));
  const month = parseInt(date.substr(5, 2));

  return new Date(year, month, 0).getDate();
};

const getNumberOfRowsHeaders = (dayOfWeek: number, formattedData: string): number => {
  const numberOfDays = getDaysCountInMonth(formattedData);

  let result = dayOfWeek + numberOfDays <= 36 ? 5 : 6;
  if (numberOfDays === 28 && dayOfWeek === 1) result = 4;

  return result;
};

const Main = (props: Props): JSX.Element => {
  if (
    props.queryDevicesList.loading ||
    !props.queryDevicesList.data ||
    props.queryShiftsList.loading ||
    !props.queryShiftsList.data
  ) {
    return <CircularProgress />;
  }

  let monthProgressiveTotal = 0;
  const devicesMonthTotals: any = {};
  const today: string = getCurrentDate();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        {
          props.queryFormsPerDateRangeList.loading || !props.queryFormsPerDateRangeList.data ? <CircularProgress /> : (
            <Paper elevation={2} className={style.box}>
              <div className={style.header}>
                <div>
                  <Button variant="contained" color="primary" onClick={props.onClickPreviousMonth}><ArrowBackIcon /></Button>
                </div>
                <div>
                  <span className={style.headerTitle}>{props.range.start.slice(0, 7)}</span>
                </div>
                <div>
                  <Button variant="contained" color="primary" onClick={props.onClickNextMonth}><ArrowForwardIcon /></Button>
                </div>
              </div>
              {
                props.queryFormsPerDateRangeList.data.formsPerDateRangeList.length ? (
                  <>
                    <div className={style.calendarContainer}>
                      <div className={style.calendarContent}>
                        <div className={style.monthColumnsHeaders}>
                          <b>Turno</b>
                          <b>Lun</b>
                          <b>Mar</b>
                          <b>Mer</b>
                          <b>Gio</b>
                          <b>Ven</b>
                          <b>Sab</b>
                          <b>Dom</b>
                        </div>
                        <div className={style.monthContainer}>
                          <div className={style.monthRowsHeaders}>
                            {
                              getRange(
                                1,
                                getNumberOfRowsHeaders(
                                  getDayOfWeek(props.queryFormsPerDateRangeList.data.formsPerDateRangeList[0].date),
                                  props.queryFormsPerDateRangeList.data.formsPerDateRangeList[0].date,
                                ),
                              ).map((item: number): JSX.Element | undefined => (
                                <div className={style.monthRowHeaderWrapper} key={`shift_label_wrapper_${item}`}>
                                  {
                                    props.queryDevicesList.data.devicesList.map((element: string): JSX.Element => (
                                      <span className={style.monthRowHeader} key={`shift_label_${item}_${element}`}>Delco {getDeviceShortName(element)}</span>
                                    ))
                                  }
                                  <span className={style.monthRowHeader} key={`shift_label_day_${item}`}>Tot. giorno</span>
                                  <span className={style.monthRowHeaderTotal} key={`shift_label_month_${item}`}>Tot. mese</span>
                                </div>
                              ))
                            }
                          </div>
                          <div className={style.month}>
                            {
                              getRange(1, 6).map((empty: number): JSX.Element | undefined => {
                                const dayOfWeek = getDayOfWeek(props.queryFormsPerDateRangeList.data.formsPerDateRangeList[0].date);
                                if (empty < dayOfWeek) {
                                  return <div key={`empty_${empty}`} />;
                                }
                                return undefined;
                              })
                            }
                            {
                              getRange(1, parseInt(props.range.end.slice(8, 10))).map((day: number): JSX.Element => {
                                const date = props.range.start.slice(0, 8) + `00${day}`.slice(-2);
                                const daysShiftsData: any = getDayShiftsData(
                                  props.queryDevicesList.data.devicesList,
                                  date,
                                  props.queryFormsPerDateRangeList.data.formsPerDateRangeList,
                                  props.queryShiftsList.data.shiftsList,
                                );
                                let dayTotal = 0;
                                const currentDate = date.substring(0, 10);
                                return (
                                  <HtmlTooltip
                                    key={`day_${day}`}
                                    placement="top"
                                    arrow
                                    open={props.selectedDay === date}
                                    interactive
                                    disableFocusListener
                                    disableHoverListener
                                    title={
                                      <TooltipContent
                                        dayShiftsData={daysShiftsData}
                                        shifts={props.queryShiftsList.data.shiftsList}
                                        devices={props.queryDevicesList.data.devicesList}
                                        date={date}
                                        onCloseTooltip={props.onCloseTooltip}
                                      />
                                    }
                                  >
                                    <div
                                      className={props.selectedDay === date ? style.selectedDay : style.day}
                                      onClick={(): void => { if (today >= currentDate) props.onOpenTooltip(date); }}
                                    >
                                      <div className={style.dayNumber}>
                                        <span>{date.slice(-2)}</span>
                                        {today === currentDate ? <div><LensIcon /></div> : <></>}
                                      </div>
                                      {
                                        today >= currentDate ? (
                                          <>
                                            {
                                              props.queryDevicesList.data.devicesList.map((device: string): JSX.Element => {
                                                const deviceDayTotal: number = getDayTotal(daysShiftsData[device]);
                                                dayTotal += deviceDayTotal;
                                                monthProgressiveTotal += deviceDayTotal;
                                                if (devicesMonthTotals[device] === undefined) devicesMonthTotals[device] = 0;
                                                devicesMonthTotals[device] += deviceDayTotal;
                                                return (
                                                  <div key={`day_item_${device}`} className={style.dayItem}>
                                                    <span className={style.dayValue}>{formatNumber(deviceDayTotal)}</span>
                                                  </div>
                                                );
                                              })
                                            }
                                            <div className={style.dayItem}>
                                              <span className={style.dayValue}>{formatNumber(dayTotal)}</span>
                                            </div>
                                            <div className={style.dayItem}>
                                              <span className={style.dayValue}>{formatNumber(monthProgressiveTotal)}</span>
                                            </div>
                                          </>
                                        ) : <></>
                                      }
                                    </div>
                                  </HtmlTooltip>
                                );
                              })
                            }
                          </div>
                        </div>
                        <div className={style.monthTotals}>
                          <div className={style.monthTotalsColumns}>
                            <div>
                              <span className={style.monthTotalsTitle}>Progressivo del mese</span>
                              {
                                Object.keys(devicesMonthTotals).map((key: string): JSX.Element => (
                                  <div key={`device_total_${key}`}>
                                    <span className={style.monthTotalsLabel}>Delco {getDeviceShortName(key)}</span>
                                    <span className={style.monthTotalsValue}>{formatNumber(devicesMonthTotals[key])}</span>
                                  </div>
                                ))
                              }
                            </div>
                            <div>
                              <span className={style.monthTotalsTitle}>Totale Generale</span>
                              <span className={style.monthTotalsValue}>{formatNumber(monthProgressiveTotal)}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : <span>Non ci sono dati disponibili per il mese selezionato</span>
              }
            </Paper>
          )
        }
      </Grid>
    </Grid>
  );
};

export default Main;
