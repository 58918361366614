/* eslint-disable @typescript-eslint/no-use-before-define */

import React, { useState } from "react";
import Main from "./components/main";
import { QUERY_DEVICES_LIVE_DATA } from "../../queries";
import { useLazyQuery } from "@apollo/react-hooks";

const LiveData = (): JSX.Element => {
  const [device, setDevice] = useState<string>("");

  const [getDeviceLiveData, deviceLiveData] = useLazyQuery(QUERY_DEVICES_LIVE_DATA, { fetchPolicy: "no-cache" });

  const onChangeDevice = (deviceValue: string): void => {
    setDevice(deviceValue);
    fetchData(deviceValue);
  };

  const fetchData = (deviceValue: any): void => {
    if (deviceValue) getDeviceLiveData({ variables: { device: deviceValue } });
  };

  return (
    <Main
      device={device}
      onChangeDevice={onChangeDevice}
      deviceLiveData={deviceLiveData}
    />
  );
};

export default LiveData;
