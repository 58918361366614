/* eslint-disable max-len */

import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import style from "./style.module.scss";
import { FormValues } from "../../";
import { Box, Button, FormControl, TextField } from "@material-ui/core";

interface Props {
  queryMaterialsList: any;
  onClickSubmit: any;
  formValues: FormValues;
  setFormValues: any;
  isSubmitButtonDisabled: boolean;
  onClickEditMaterial: any;
  onEditMaterialId: string;
  onClickReset: any;
}

const Main = (props: Props): JSX.Element => {
  if (
    props.queryMaterialsList.loading ||
    !props.queryMaterialsList.data
  ) {
    return <CircularProgress />;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box mb={4}>
          <div className={style.formWrapper}>
            <Paper>
              <div className={style.form}>
                <div>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      size="small"
                      variant="outlined"
                      name="nome"
                      label="Nome"
                      type="text"
                      value={props.formValues?.name}
                      onChange={(event: any): void => props.setFormValues({ ...props.formValues, name: event.target.value })}
                    />
                  </FormControl>
                </div>
                <div>
                  <Button
                    variant="contained"
                    onClick={props.onClickReset}
                  >
                    Annulla
                  </Button>
                </div>
                <div>
                  <Button
                    variant="contained"
                    onClick={props.onClickSubmit}
                    color="primary"
                    disabled={props.isSubmitButtonDisabled}
                  >
                    {props.onEditMaterialId ? "Aggiorna" : "Aggiungi"}
                  </Button>
                </div>
              </div>
            </Paper>
          </div>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <div className={style.contentWrapper}>
          <Paper className={style.cardContentWrapper}>
            <div className={style.table}>
              <table>
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {
                    props.queryMaterialsList.data.materialsList.map((material: any): JSX.Element => (
                      <tr key={`material_${material.id}`}>
                        <td>{material.name}</td>
                        <td>
                          <Button
                            color="primary"
                            onClick={(): void => props.onClickEditMaterial(material.id)}
                            disabled={props.onEditMaterialId !== ""}
                          >
                            Modifica
                          </Button>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </Paper>
        </div>
      </Grid>
    </Grid >
  );
};

export default Main;
