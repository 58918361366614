import { CircularProgress, Paper, Switch } from "@material-ui/core";
import React from "react";
import style from "./style.module.scss";

interface Props {
  isEmailEnabled: boolean;
  onChangeEnableEmail: any;
  isLoading: boolean;
}

const Main = (props: Props): JSX.Element => {
  if(props.isLoading) return <CircularProgress />;
  return (
    <Paper>
      <div className={style.wrapper}>
        <span className={style.title}>Notifiche email</span>
        <span className={style.label}>Abilia o disabilita l&apos;invio di email di notifica in caso di dispositivi offline</span>
        <Switch
          checked={props.isEmailEnabled}
          onChange={props.onChangeEnableEmail}
          name="isEmailEnabled"
          inputProps={{ "aria-label": "Abilita o disabilita invio" }}
          color="primary"
        />
      </div>
    </Paper>
  );
};

export default Main;
