/* eslint-disable max-len */

import React from "react";
import style from "./style.module.scss";
import { FormValues, DeviceFormValues, AdditionalFormMaterial, FormStop } from "../../";
import { Box, Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getDeviceShortName, isUserAuthorized } from "../../../../utils";
import ShowHide from "../../../../components/show-hide";

interface Props {
  formValues: FormValues | undefined;
  setFormValues: any;
  queryDevicesList: any;
  queryShiftsList: any;
  queryMaterialsList: any;
  onSelectDevice: any;
  selectedDevice: string;
  onClickSaveAsDraft: any;
  onClickSaveAsDefinitive: any;
  errors: Array<string>;
  isShowSuccessVisible: number;
  queryPendingFormDetails: any;
  queryUsersList: any;
}

const updateDeviceForm = (formValues: FormValues | undefined, setFormValues: any, device: string, key: string, value: any): void => {
  setFormValues(
    {
      ...formValues,
      devicesFormsValues: [
        ...formValues?.devicesFormsValues.filter((deviceFormValue: DeviceFormValues): boolean => deviceFormValue.device !== device) ?? [],
        {
          ...formValues?.devicesFormsValues.find((deviceFormValue: DeviceFormValues): boolean => deviceFormValue.device === device) ?? {},
          [key]: value,
        },
      ],
    },
  );
};

const onClickAddAdditionalFormMaterial = (formValues: FormValues | undefined, setFormValues: any, device: string): void => {
  setFormValues(
    {
      ...formValues,
      devicesFormsValues: [
        ...formValues?.devicesFormsValues.filter((deviceFormValue: DeviceFormValues): boolean => deviceFormValue.device !== device) ?? [],
        {
          ...formValues?.devicesFormsValues.find((deviceFormValue: DeviceFormValues): boolean => deviceFormValue.device === device) ?? {},
          additionalFormMateriels: [
            ...formValues?.devicesFormsValues.find((deviceFormValue: DeviceFormValues): boolean => deviceFormValue.device === device)?.additionalFormMateriels ?? [],
            {
              material: 0,
              quantity: 0,
              purgedQuantity: 0,
            },
          ],
        },
      ],
    },
  );
};

const onPressRemoveAdditionalFormMaterial = (formValues: FormValues | undefined, setFormValues: any, device: string, index: number): void =>{
  setFormValues(
    {
      ...formValues,
      devicesFormsValues: [
        ...formValues?.devicesFormsValues.filter((deviceFormValue: DeviceFormValues): boolean => deviceFormValue.device !== device) ?? [],
        {
          ...formValues?.devicesFormsValues.find((deviceFormValue: DeviceFormValues): boolean => deviceFormValue.device === device) ?? {},
          additionalFormMateriels: formValues?.devicesFormsValues.find((deviceFormValue: DeviceFormValues): boolean => deviceFormValue.device === device)?.additionalFormMateriels?.filter((item: AdditionalFormMaterial, itemIndex: number): boolean => itemIndex !== index) ?? [],
        },
      ],
    },
  );
};

const onClickRemoveFormStop = (formValues: FormValues | undefined, setFormValues: any, device: string, index: number): void =>{
  setFormValues(
    {
      ...formValues,
      devicesFormsValues: [
        ...formValues?.devicesFormsValues.filter((deviceFormValue: DeviceFormValues): boolean => deviceFormValue.device !== device) ?? [],
        {
          ...formValues?.devicesFormsValues.find((deviceFormValue: DeviceFormValues): boolean => deviceFormValue.device === device) ?? {},
          formStops: formValues?.devicesFormsValues.find((deviceFormValue: DeviceFormValues): boolean => deviceFormValue.device === device)?.formStops?.filter((item: FormStop, itemIndex: number): boolean => itemIndex !== index) ?? [],
        },
      ],
    },
  );
};

const getAdditionalFormMaterialValue = (formValues: any, device: string, index: number, key: string): any => {
  const additionalFormMateriels = formValues?.devicesFormsValues.find((devicesFormValues: DeviceFormValues): boolean => devicesFormValues.device === device)?.additionalFormMateriels;
  if (additionalFormMateriels) return additionalFormMateriels[index][key];
  return 0;
};

const updateAdditionalFormMaterial = (formValues: FormValues | undefined, setFormValues: any, device: string, index: number, key: string, value: any): void => {
  if (formValues) {
    const deviceFormsValues = formValues?.devicesFormsValues.find((deviceFormValue: DeviceFormValues): boolean => deviceFormValue.device === device);
    const otherDevicesFormsValues = formValues?.devicesFormsValues.filter((deviceFormValue: DeviceFormValues): boolean => deviceFormValue.device !== device);
    if (deviceFormsValues) {
      const additionalFormMateriels = deviceFormsValues?.additionalFormMateriels;
      if (additionalFormMateriels) {
        setFormValues({
          ...formValues,
          devicesFormsValues: [
            ...otherDevicesFormsValues,
            {
              ...deviceFormsValues,
              additionalFormMateriels: additionalFormMateriels.map((element: any, elementIndex: number): any => elementIndex === index ? { ...element, [key]: value } : element),
            },
          ],
        });
      }
    }
  }
};

const onPressAddFormStop = (formValues: FormValues | undefined, setFormValues: any, device: string): void => {
  setFormValues(
    {
      ...formValues,
      devicesFormsValues: [
        ...formValues?.devicesFormsValues.filter((deviceFormValue: DeviceFormValues): boolean => deviceFormValue.device !== device) ?? [],
        {
          ...formValues?.devicesFormsValues.find((deviceFormValue: DeviceFormValues): boolean => deviceFormValue.device === device) ?? {},
          formStops: [
            ...formValues?.devicesFormsValues.find((deviceFormValue: DeviceFormValues): boolean => deviceFormValue.device === device)?.formStops ?? [],
            {
              start: "",
              end: "",
              reason: "",
            },
          ],
        },
      ],
    },
  );
};

const getFormStopValue = (formValues: any, device: string, index: number, key: string): any => {
  const formStops = formValues?.devicesFormsValues.find((devicesFormValues: DeviceFormValues): boolean => devicesFormValues.device === device)?.formStops;
  if (formStops) return formStops[index][key];
  return "";
};

const getDeviceFormValue = (formValues: any, device: string, key: string): any => {
  const deviceFormValues = formValues?.devicesFormsValues.find((devicesFormValues: DeviceFormValues): boolean => devicesFormValues.device === device);
  if (deviceFormValues) return deviceFormValues[key];
  return "";
};

const updateFormStop = (formValues: FormValues | undefined, setFormValues: any, device: string, index: number, key: string, value: any): void => {
  if (formValues) {
    const deviceFormsValues = formValues?.devicesFormsValues.find((deviceFormValue: DeviceFormValues): boolean => deviceFormValue.device === device);
    const otherDevicesFormsValues = formValues?.devicesFormsValues.filter((deviceFormValue: DeviceFormValues): boolean => deviceFormValue.device !== device);
    if (deviceFormsValues) {
      const formStops = deviceFormsValues?.formStops;
      if (formStops) {
        setFormValues({
          ...formValues,
          devicesFormsValues: [
            ...otherDevicesFormsValues,
            {
              ...deviceFormsValues,
              formStops: formStops.map((element: any, elementIndex: number): any => elementIndex === index ? { ...element, [key]: value } : element),
            },
          ],
        });
      }
    }
  }
};

const updateDeviceFormValues = (formValues: FormValues | undefined, setFormValues: any, device: string, key: string, value: any): void => {
  if (formValues) {
    const deviceFormsValues = formValues?.devicesFormsValues.find((deviceFormValue: DeviceFormValues): boolean => deviceFormValue.device === device);
    const otherDevicesFormsValues = formValues?.devicesFormsValues.filter((deviceFormValue: DeviceFormValues): boolean => deviceFormValue.device !== device);
    if (deviceFormsValues) {

      setFormValues({
        ...formValues,
        devicesFormsValues: [
          ...otherDevicesFormsValues,
          {
            ...deviceFormsValues,
            [key]: value,
          },
        ],
      });

    }
  }
};

const Form = (props: Props): JSX.Element => {
  if (
    props.queryDevicesList.loading ||
    !props.queryDevicesList.data ||
    props.queryShiftsList.loading ||
    !props.queryShiftsList.data ||
    props.queryMaterialsList.loading ||
    !props.queryMaterialsList.data ||
    props.queryPendingFormDetails.loading ||
    !props.queryPendingFormDetails.data ||
    props.queryUsersList.loading ||
    !props.queryUsersList.data ||
    !props.formValues
  ) {
    return <CircularProgress />;
  }

  return (
    <div className={style.wrapper}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Paper elevation={2}>
            <div className={style.content}>
              {
                props.isShowSuccessVisible === 1 ? (
                  <div className={style.successWrapper}>
                    <Alert severity="success">Modulo salvato, puoi procedere inserendo un altro modulo</Alert>
                  </div>
                ) : <></>
              }
              {
                props.isShowSuccessVisible === 2 ? (
                  <div className={style.successWrapper}>
                    <Alert severity="success">Modulo salvato per dopo, puoi continuare ad inserire i dati</Alert>
                  </div>
                ) : <></>
              }
              <div className={style.threeColumns}>
                {
                  isUserAuthorized(1) ? (
                    <div>
                      {
                        <FormControl variant="outlined" fullWidth>
                          <Autocomplete
                            id="material"
                            options={props.queryUsersList.data.usersList}
                            getOptionLabel={(option: any): any => option?.name ?? ""}
                            renderInput={(params: any): any => <TextField {...params} label="Utente" variant="outlined" />}
                            onChange={(event: any, option: any): void => props.setFormValues({...props.formValues,  user: option ? parseInt(option.id) : 0})}
                            value={props.queryUsersList.data.usersList.find((user: any): boolean => user.id === parseInt(props.formValues?.user?.toString() ?? "0"))}
                          />
                        </FormControl>
                      }
                    </div>
                  ) : <></>
                }
                <div>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      variant="outlined"
                      name="date"
                      label="Data"
                      type="date"
                      value={props.formValues?.date}
                      onChange={(event: any): void => props.setFormValues({ ...props.formValues, date: event.target.value })}
                    />
                  </FormControl>
                </div>
                <div>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Turno</InputLabel>
                    <Select
                      value={props.formValues?.shift ?? ""}
                      onChange={(event: any): void => props.setFormValues({ ...props.formValues, shift: event.target.value })}
                      label="Turno"
                      name="shift"
                    >
                      {
                        props.queryShiftsList.data.shiftsList.map((shift: string): JSX.Element => (
                          <MenuItem key={`shift_${shift}`} value={shift}>{shift}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </div>
              </div>
              <Box mt={4} mb={4}>
                {
                  props.queryDevicesList.data.devicesList.map((device: string): JSX.Element => (
                    <div key={`device_${device}`} className={style.deviceTabWrapper}>
                      <Button
                        variant={props.selectedDevice === device ? "contained" : "outlined"}
                        color="primary"
                        onClick={(): void => props.onSelectDevice(device)}
                      >
                        Delco {getDeviceShortName(device)}
                      </Button>
                    </div>
                  ))
                }
              </Box>
              {
                props.queryDevicesList.data.devicesList.map((device: string): JSX.Element => (
                  <ShowHide key={`show_hide_${device}`} isVisible={props.selectedDevice === device}>
                    <div className={style.boxWrapper}>
                      <div className={style.boxTitle}>
                        <span>Materiali prodotti Delco {getDeviceShortName(device)}</span>
                      </div>
                      <div className={style.fourColumns}>
                        <div>
                          <FormControl variant="outlined" fullWidth>
                            <Autocomplete
                              id="material"
                              options={props.queryMaterialsList.data.materialsList}
                              getOptionLabel={(option: any): any => option?.name ?? ""}
                              renderInput={(params: any): any => <TextField {...params} label="Materiale" variant="outlined" />}
                              onChange={(event: any, option: any): void => updateDeviceForm(props.formValues, props.setFormValues, device, "material", option ? parseInt(option.id) : 0)}
                              value={props.queryMaterialsList.data.materialsList.find((material: any): boolean => parseInt(material.id) === props.formValues?.devicesFormsValues.find((devicesFormValues: DeviceFormValues): boolean => devicesFormValues.device === device)?.material) || 0}
                            />
                          </FormControl>
                        </div>
                        <div>
                          <FormControl variant="outlined" fullWidth>
                            <TextField
                              variant="outlined"
                              name="quantity"
                              label="Quantità"
                              type="number"
                              value={props.formValues?.devicesFormsValues.find((devicesFormValues: DeviceFormValues): boolean => devicesFormValues.device === device)?.quantity}
                              onChange={(event: any): void => updateDeviceForm(props.formValues, props.setFormValues, device, event.target.name, event.target.value)}
                            />
                          </FormControl>
                        </div>
                      </div>
                      {
                        props.formValues?.devicesFormsValues.find((deviceFormValue: DeviceFormValues): boolean => deviceFormValue.device === device)?.additionalFormMateriels?.map((additionalFormMaterial: AdditionalFormMaterial, index: number): JSX.Element => (
                          <div key={`additional_material_${device}_${index}`} className={style.additionalMaterialWrapper}>
                            <div className={style.fiveColumns}>
                              <div>
                                <FormControl variant="outlined" fullWidth>
                                  <TextField
                                    variant="outlined"
                                    name="time"
                                    label="Orario"
                                    type="time"
                                    value={getAdditionalFormMaterialValue(props.formValues, device, index, "time")}
                                    onChange={(event: any): void => updateAdditionalFormMaterial(props.formValues, props.setFormValues, device, index, event.target.name, event.target.value)}
                                  />
                                </FormControl>
                              </div>
                              <div>
                                <FormControl variant="outlined" fullWidth>
                                  <Autocomplete
                                    id="material"
                                    options={props.queryMaterialsList.data.materialsList}
                                    getOptionLabel={(option: any): any => option?.name ?? ""}
                                    renderInput={(params: any): any => <TextField {...params} label="Materiale" variant="outlined" />}
                                    onChange={(event: any, option: any): void => updateAdditionalFormMaterial(props.formValues, props.setFormValues, device, index, "material", option ? parseInt(option.id) : 0)}
                                    value={props.queryMaterialsList.data.materialsList.find((material: any): boolean => parseInt(material.id) === getAdditionalFormMaterialValue(props.formValues, device, index, "material")) || 0}
                                  />
                                </FormControl>
                              </div>
                              <div>
                                <FormControl variant="outlined" fullWidth>
                                  <TextField
                                    variant="outlined"
                                    name="quantity"
                                    label="Quantità"
                                    type="number"
                                    value={getAdditionalFormMaterialValue(props.formValues, device, index, "quantity")}
                                    onChange={(event: any): void => updateAdditionalFormMaterial(props.formValues, props.setFormValues, device, index, event.target.name, event.target.value)}
                                  />
                                </FormControl>
                              </div>
                              <div>
                                <FormControl variant="outlined" fullWidth>
                                  <TextField
                                    variant="outlined"
                                    name="purgedQuantity"
                                    label="Spurgo"
                                    type="number"
                                    value={getAdditionalFormMaterialValue(props.formValues, device, index, "purgedQuantity")}
                                    onChange={(event: any): void => updateAdditionalFormMaterial(props.formValues, props.setFormValues, device, index, event.target.name, event.target.value)}
                                  />
                                </FormControl>
                              </div>
                              <Button
                                color="secondary"
                                onClick={(): void => onPressRemoveAdditionalFormMaterial(props.formValues, props.setFormValues, device, index)}
                              >
                                Rimuovi
                              </Button>
                            </div>
                          </div>
                        ))
                      }
                      <div className={style.addMaterialWrapper}>
                        <Button color="primary" onClick={(): void => onClickAddAdditionalFormMaterial(props.formValues, props.setFormValues, device)}>Aggiungi altro materiale prodotto</Button>
                      </div>
                    </div>
                    <div className={style.boxWrapper}>
                      <div className={style.boxTitle}>
                        <span>Fermi macchina Delco {getDeviceShortName(device)}</span>
                      </div>
                      {
                        props.formValues?.devicesFormsValues.find((deviceFormValue: DeviceFormValues): boolean => deviceFormValue.device === device)?.formStops?.map((formStop: FormStop, index: number): JSX.Element => (
                          <div key={`form_stop_${device}_${index}`} className={style.stopWrapper}>
                            <div className={style.fourColumnsWithButton}>
                              <div>
                                <FormControl variant="outlined" fullWidth>
                                  <TextField
                                    variant="outlined"
                                    name="start"
                                    label="Inizio"
                                    type="time"
                                    value={getFormStopValue(props.formValues, device, index, "start")}
                                    onChange={(event: any): void => updateFormStop(props.formValues, props.setFormValues, device, index, event.target.name, event.target.value)}
                                  />
                                </FormControl>
                              </div>
                              <div>
                                <FormControl variant="outlined" fullWidth>
                                  <TextField
                                    variant="outlined"
                                    name="end"
                                    label="Fine"
                                    type="time"
                                    value={getFormStopValue(props.formValues, device, index, "end")}
                                    onChange={(event: any): void => updateFormStop(props.formValues, props.setFormValues, device, index, event.target.name, event.target.value)}
                                  />
                                </FormControl>
                              </div>
                              <div>
                                <FormControl variant="outlined" fullWidth>
                                  <TextField
                                    variant="outlined"
                                    name="reason"
                                    label="Motivo del fermo"
                                    type="txet"
                                    value={getFormStopValue(props.formValues, device, index, "reason")}
                                    onChange={(event: any): void => updateFormStop(props.formValues, props.setFormValues, device, index, event.target.name, event.target.value)}
                                  />
                                </FormControl>
                              </div>
                              <Button
                                color="secondary"
                                onClick={(): void => onClickRemoveFormStop(props.formValues, props.setFormValues, device, index)}
                              >
                                Rimuovi
                              </Button>
                            </div>
                          </div>
                        ))
                      }
                      <div className={style.addMaterialWrapper}>
                        <Button color="secondary" onClick={(): void => onPressAddFormStop(props.formValues, props.setFormValues, device)}>Registra fermo macchina</Button>
                      </div>
                    </div>
                    <div className={style.boxWrapper}>
                      <div className={style.boxTitle}>
                        <span>Annotazioni Delco {getDeviceShortName(device)}</span>
                      </div>

                      <div className={style.extendedNoteWrapper}>
                        <div>
                          <FormControl variant="outlined" fullWidth>
                            <TextField
                              variant="outlined"
                              name="noteOne"
                              label="Lame installate"
                              type="txet"
                              value={getDeviceFormValue(props.formValues, device, "noteOne")}
                              onChange={(event: any): void => updateDeviceFormValues(props.formValues, props.setFormValues, device, "noteOne", event.target.value) }
                            />
                          </FormControl>
                        </div>
                        <div>
                          <FormControl variant="outlined" fullWidth>
                            <TextField
                              variant="outlined"
                              name="noteTwo"
                              label="Note addizionali"
                              multiline
                              rows={4}
                              value={getDeviceFormValue(props.formValues, device, "noteTwo")}
                              onChange={(event: any): void => updateDeviceFormValues(props.formValues, props.setFormValues, device, "noteTwo", event.target.value) }
                            />
                          </FormControl>
                        </div>
                      </div>
                      <div className={style.flagWrapper}>
                        <span>Paggio consegne e pulizia luogo di lavoro</span>
                        <Button
                          variant="outlined"
                          color={getDeviceFormValue(props.formValues, device, "suitable") ? "primary" : "secondary"}
                          onClick={(): void => updateDeviceFormValues(props.formValues, props.setFormValues, device, "suitable", !props.formValues?.devicesFormsValues.find((item: DeviceFormValues): boolean => item.device === device)?.suitable)}
                        >
                          {getDeviceFormValue(props.formValues, device, "suitable") ? "Idoneo" : "Non idoneo"}
                        </Button>
                      </div>

                    </div>
                  </ShowHide>
                ))
              }
              <div className={style.noteWrapper}>
                {
                  props.errors.length ? (
                    <div className={style.errorsWrapper}>
                      {
                        props.errors.map((error: string, index: number): JSX.Element => (
                          <Alert key={`error_${index}`} severity="error">{error}</Alert>
                        ))
                      }
                    </div>
                  ) : <></>
                }
                <div className={style.submitWrapper}>
                  <div>
                    {
                      !isUserAuthorized(1) ? (
                        <Button
                          variant="contained"
                          onClick={props.onClickSaveAsDraft}
                        >
                          Salva questo modulo per dopo
                        </Button>
                      ) : <></>
                    }
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={props.onClickSaveAsDefinitive}
                    >
                      Il modulo &egrave; stato completato
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div >
  );
};

export default Form;
