import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import style from "./style.module.scss";
import { getDeviceColor, getDeviceShortName, formatNumber } from "../../../../utils";
import { Refresh as RefreshIcon } from "@material-ui/icons";
import { Button } from "@material-ui/core";

interface Props {
  queryDevicesList: any;
  queryDevicesStatuses: any;
  onSelectTab: any;
  selectedTab: number;
  children: any;
}

const getSpeedPerHourColumn = (device: string): string => {
  if (device === "00003") return "221_KG_H_REALI";
  if (device === "00005") return "";
  return "080_KG_H_REALI";
};

const getSpeedSetColumn = (device: string): string => {
  if (device === "00003") return "220_KG_H_IMPOSTATI";
  if (device === "00005") return "";
  return "079_KG_H_IMPOSTATI";
};

const getWorkingColumn = (device: string): string => {
  if (device === "00003") return "103_GVMotoreEstrusore";
  if (device === "00005") return "103_AmpereMotoreMulino";
  return "063A_GVMotoreEstrusore";
};

const getAmpereColumn = (device: string): string => {
  if (device === "00004") return "103_AmpereMotoreEstrusore";
  if (device === "00005") return "103_AmpereMotoreMulino";
  return "";
};

const getDeviceStatus = (device: string, data: Array<any>): any => {
  if (data !== undefined) {
    const deviceData = JSON.parse(data.find((record: any): any => record.device === device).data);
    if (deviceData.length) {
      let isOnline = false;
      let isWorking = false;
      let product = "";
      const speedPerHour = parseFloat(JSON.parse(deviceData[0].data)[getSpeedPerHourColumn(device)]);
      const speedSet = parseFloat(JSON.parse(deviceData[0].data)[getSpeedSetColumn(device)]);
      const ampere = parseFloat(JSON.parse(deviceData[0].data)[getAmpereColumn(device)]);
      if (deviceData.length && parseFloat(JSON.parse(deviceData[0].data)[getWorkingColumn(device)]) > 0) isWorking = true;
      if (deviceData.length) isOnline = true;
      if (isWorking) product = JSON.parse(deviceData[0].data)["100_Ricetta"]?.slice(0, -7);
      return { device, isOnline, isWorking, speedPerHour, speedSet, product, ampere };
    }
  }
  return {};
};

const Header = (props: Props): JSX.Element => {
  if (
    props.queryDevicesList.loading ||
    !props.queryDevicesList.data
  ) {
    return <CircularProgress />;
  }
  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <div className={style.deviceCardWrapper}>
            <div>
              <Paper elevation={2}>
                <Grid container>
                  <Grid item className={style.deviceWrapper}>
                    {
                      props.queryDevicesList.data.devicesList.map((device: string): JSX.Element => {
                        const deviceStatus = getDeviceStatus(device, props.queryDevicesStatuses.data?.devicesStatuses);
                        return (
                          <div key={device}>
                            <span className={style.deviceName} style={{ backgroundColor: getDeviceColor(device) }}>{getDeviceShortName(device)}</span>
                            {
                              props.queryDevicesStatuses.loading || !props.queryDevicesStatuses.data ? (
                                <div className={style.deviceDotLoading}>
                                  <RefreshIcon className={style.deviceDotLoadingIcon} />
                                </div>
                              ) : (
                                <div className={deviceStatus.isOnline ? style.deviceDotOnline : style.deviceDotOffline} />
                              )
                            }
                            <div>
                              <span className={style.deviceStatus}>{deviceStatus.isOnline ? "Online" : "Offline"}</span>
                              <span className={style.deviceWorking}>{deviceStatus.isWorking ? "Working" : "Not Working"}</span>
                              <span className={style.deviceSpeed}>
                                {
                                  (deviceStatus.isWorking && !deviceStatus.ampere) ? (
                                    <><b>{formatNumber(deviceStatus.speedPerHour)}</b>/{formatNumber(deviceStatus.speedSet)} Kg/H</>
                                  ) : "--"
                                }
                                {
                                  (deviceStatus.isWorking && deviceStatus.ampere) ? (
                                    <><b>{formatNumber(deviceStatus.ampere)}</b> Amp</>
                                  ) : "--"
                                }
                              </span>
                              <span className={style.deviceProduct}>
                                {
                                  deviceStatus.product ? (
                                    <>{deviceStatus.product}</>
                                  ) : "--"
                                }
                              </span>
                            </div>
                          </div>
                        );
                      })
                    }
                  </Grid>
                </Grid>
              </Paper>
            </div>
            <div style={{ display: "none" }}>
              <Paper elevation={2} className={style.tabsWrapper}>
                <div className={style.tabWrapper}>
                  <Button
                    variant={props.selectedTab === 0 ? "contained" : "outlined"}
                    color="primary"
                    onClick={(): void => props.onSelectTab(0)}
                  >
                    Dati dispositivi
                  </Button>
                </div>
                <div className={style.tabWrapper}>
                  <Button
                    variant={props.selectedTab === 1 ? "contained" : "outlined"}
                    color="primary"
                    onClick={(): void => props.onSelectTab(1)}
                  >
                    Dati moduli
                  </Button>
                </div>
              </Paper>
            </div>
          </div>
        </Grid>
      </Grid>
      <>
        {props.children}
      </>
    </>
  );
};

export default Header;
