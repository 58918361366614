import { Paper } from "@material-ui/core";
import React from "react";
import style from "./style.module.scss";

const Main = (): JSX.Element => {
  return (
    <Paper>
      <iframe title="Pannello" src={`${process.env.REACT_APP_BACKEND_URL}plc-contents-login`} className={style.wrapper} />
    </Paper>
  );
};

export default Main;
