import { useMutation } from "@apollo/react-hooks";
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { MUTATION_LOGOUT } from "../../queries";
import { isLoggedIn } from "../../utils";
import Layout from "./components/layout";
import { withRouter } from "react-router-dom";

type Props = any;

const PrivateRoute = (props: Props): JSX.Element => {
  const [logout] = useMutation(MUTATION_LOGOUT);

  const onClickLogout = (): void => {
    logout();
    localStorage.removeItem("accessToken");
    props.history.push("/login");
  };

  const onClickSettings = (): void => {
    props.history.push("/settings");
  };

  const Component = props.component;
  const userType = props.userType;
  const rest = { ...props, component: undefined, userType: undefined };
  return (
    <Route {...rest} render={(data: any): JSX.Element => (
      isLoggedIn(userType) ? <Layout onClickLogout={onClickLogout} onClickSettings={onClickSettings}><Component {...data} /></Layout> : <Redirect to="/login" />
    )}
    />
  );
};

export default withRouter(PrivateRoute);
