import { addDays, addMonths, endOfMonth, format, isValid, parse, startOfMonth, subDays, subMinutes, subMonths } from "date-fns";
import getDay from "date-fns/getDay";
import jwt_decode from "jwt-decode";

export const isLoggedIn = (userTypeToCheck: number): boolean => {
  const accessToken: string | null = localStorage.getItem("accessToken");
  if (accessToken !== null) {
    const decodedJwt: any = accessToken ? jwt_decode(accessToken) : undefined;
    if (accessToken !== undefined) {
      if (parseInt(decodedJwt.scopes[0]) <= userTypeToCheck) return true;
    }
  }
  return false;
};

export const isUserAuthorized = (userTypeToCheck: number, strict?: boolean): boolean => {
  const accessToken: string | null = localStorage.getItem("accessToken");
  if (accessToken !== null) {
    const decodedJwt: any = accessToken ? jwt_decode(accessToken) : undefined;
    if (accessToken !== undefined) {
      if (strict && parseInt(decodedJwt.scopes[0]) === userTypeToCheck) return true;
      if (!strict && parseInt(decodedJwt.scopes[0]) <= userTypeToCheck) return true;
    }
  }
  return false;
};

export const getDeviceColor = (device: string): string => {
  const parsed: number = parseInt(device);
  switch (parsed) {
  case 1:
    return "#313B72";
  case 2:
    return "#019587";
  case 3:
    return "#02A7F3";
  case 4:
    return "#FD7301";
  case 5:
    return "#9C26B0";
  case 6:
    return "#603FB1";
  case 7:
    return "#603FB1";
  case 8:
    return "#9D9D9D";
  case 9:
    return "#603FB1";
  default:
    return "#603FB1";
  }
};

export const getCurrentDate = (): string => format(new Date(), "yyyy-MM-dd");

export const formatDate = (date: string): string => format(new Date(date), "dd/MM/yyyy");

export const getPreviousDate = (date: string): string => format(subDays(parse(date, "yyyy-MM-dd", new Date()), 1), "yyyy-MM-dd");

export const getNextDate = (date: string): string => format(addDays(parse(date, "yyyy-MM-dd", new Date()), 1), "yyyy-MM-dd");

export const getCurrentMonthStart = (): string => format(startOfMonth(new Date()), "yyyy-MM-dd");

export const getCurrentMonthEnd = (): string => format(endOfMonth(new Date()), "yyyy-MM-dd");

export const getPreviousMonthStart = (date: string): any => format(startOfMonth(subMonths(parse(date.slice(0, 10), "yyyy-MM-dd", new Date()), 1)), "yyyy-MM-dd");

export const getPreviousMonthEnd = (date: string): string => format(endOfMonth(subMonths(parse(date.slice(0, 10), "yyyy-MM-dd", new Date()), 1)), "yyyy-MM-dd");

export const getNextMonthStart = (date: string): string => format(startOfMonth(addMonths(parse(date.slice(0, 10), "yyyy-MM-dd", new Date()), 1)), "yyyy-MM-dd");

export const getNextMonthEnd = (date: string): string => format(endOfMonth(addMonths(parse(date.slice(0, 10), "yyyy-MM-dd", new Date()), 1)), "yyyy-MM-dd");

export const getDeviceShortName = (device: string): string => {
  if(device === "00004") return "M40";
  if(device === "00005") return "ML130";
  return device.slice(-2);
};

export const getRange = (start: number, end: number): Array<number> => {
  const result: Array<number> = [];
  for (let index = start; index <= end; index++) {
    result.push(index);
  }
  return result;
};

export const getDayOfWeek = (date: string): number => {
  const parsedDate = parse(date, "yyyy-MM-dd", new Date());
  if (getDay(parsedDate) === 0) {
    return 7;
  }
  return getDay(parsedDate);
};

export const getLastMinutes = (): any => {
  const now = new Date();
  const startDate = subMinutes(now, 5);
  return { start: format(startDate, "yyyy-MM-dd HH:mm:ss") };
};

export const formatNumber = (number: number): string => number.toLocaleString("it", { minimumFractionDigits: 2, maximumFractionDigits: 2 });

export const getColorsList = (count: number): Array<string> => {
  const colors: Array<string> = [
    "#EF476F",
    "#0C7489",
    "#C9EDDC",
    "#040404",
    "#D7D9CE",
    "#B39C4D",
    "#EFF1ED",
    "#BCBD8B",
    "#9FCC2E",
    "#E8F7EE",
    "#89B6A5",
    "#4C3B4D",
    "#13505B",
    "#82968C",
    "#6A706E",
    "#ED254E",
    "#BD8B9C",
    "#C492B1",
    "#119DA4",
    "#D4ADCF",
  ];
  return colors.slice(0, count);
};

export const getColumDescription = (name: string): string => {
  const list = name.split("_");
  const partial = list[list.length - 1];
  return partial.slice(0, 1).toUpperCase() + partial.slice(1).toLowerCase();
};

export const isValidTime = (time: string): boolean => isValid(parse(time, "HH:mm", new Date()));

export const isValidDate = (date: string): boolean => isValid(parse(date, "yyyy-MM-dd", new Date()));

export const isNotBetweenTimes = (time: string, start: string, end: string): boolean => (
  (start < end && (time < start || time > end)) ||
  (start > end && ((time < start && time > end) || (time > start && time < end)))
);

export const SHITS_TIMES: any = {
  "1": [
    "06:00:00",
    "06:10:00",
    "06:20:00",
    "06:30:00",
    "06:40:00",
    "06:50:00",

    "07:00:00",
    "07:10:00",
    "07:20:00",
    "07:30:00",
    "07:40:00",
    "07:50:00",

    "08:00:00",
    "08:10:00",
    "08:20:00",
    "08:30:00",
    "08:40:00",
    "08:50:00",

    "09:00:00",
    "09:10:00",
    "09:20:00",
    "09:30:00",
    "09:40:00",
    "09:50:00",

    "10:00:00",
    "10:10:00",
    "10:20:00",
    "10:30:00",
    "10:40:00",
    "10:50:00",

    "11:00:00",
    "11:10:00",
    "11:20:00",
    "11:30:00",
    "11:40:00",
    "11:50:00",

    "12:00:00",
    "12:10:00",
    "12:20:00",
    "12:30:00",
    "12:40:00",
    "12:50:00",

    "13:00:00",
    "13:10:00",
    "13:20:00",
    "13:30:00",
    "13:40:00",
    "13:50:00",
  ],
  "2": [
    "14:00:00",
    "14:10:00",
    "14:20:00",
    "14:30:00",
    "14:40:00",
    "14:50:00",

    "15:00:00",
    "15:10:00",
    "15:20:00",
    "15:30:00",
    "15:40:00",
    "15:50:00",

    "16:00:00",
    "16:10:00",
    "16:20:00",
    "16:30:00",
    "16:40:00",
    "16:50:00",

    "17:00:00",
    "17:10:00",
    "17:20:00",
    "17:30:00",
    "17:40:00",
    "17:50:00",

    "18:00:00",
    "18:10:00",
    "18:20:00",
    "18:30:00",
    "18:40:00",
    "18:50:00",

    "19:00:00",
    "19:10:00",
    "19:20:00",
    "19:30:00",
    "19:40:00",
    "19:50:00",

    "20:00:00",
    "20:10:00",
    "20:20:00",
    "20:30:00",
    "20:40:00",
    "20:50:00",

    "21:00:00",
    "21:10:00",
    "21:20:00",
    "21:30:00",
    "21:40:00",
    "21:50:00",
  ],
  "3": [
    "22:00:00",
    "22:10:00",
    "22:20:00",
    "22:30:00",
    "22:40:00",
    "22:50:00",

    "23:00:00",
    "23:10:00",
    "23:20:00",
    "23:30:00",
    "23:40:00",
    "23:50:00",

    "00:00:00",
    "00:10:00",
    "00:20:00",
    "00:30:00",
    "00:40:00",
    "00:50:00",

    "01:00:00",
    "01:10:00",
    "01:20:00",
    "01:30:00",
    "01:40:00",
    "01:50:00",

    "02:00:00",
    "02:10:00",
    "02:20:00",
    "02:30:00",
    "02:40:00",
    "02:50:00",

    "03:00:00",
    "03:10:00",
    "03:20:00",
    "03:30:00",
    "03:40:00",
    "03:50:00",

    "04:00:00",
    "04:10:00",
    "04:20:00",
    "04:30:00",
    "04:40:00",
    "04:50:00",

    "05:00:00",
    "05:10:00",
    "05:20:00",
    "05:30:00",
    "05:40:00",
    "05:50:00",
  ],
};

export const filterHomeStatsDevices = (device: string): boolean => !["00004", "00005"].includes(device);
