/* eslint-disable max-len */

import React, { useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { getDeviceColor, getDeviceShortName } from "../../../../utils";
import style from "./style.module.scss";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Input from "../../../../components/input";
import { Select, MenuItem, FormControl } from "@material-ui/core";

interface Props {
  queryDevicesList: any;
  queryDeviceSettings: any;
  queryDeviceJobsList: any;
  formValues: any;
  setFormValues: any;
  isAddModalVisible: any;
  onClickAddDeviceJob: any;
  onClickCancelAddDeviceJob: any;
  onClickSaveDeviceJob: any;
  onClickDeleteDeviceJob: any;
  onClickStartWork: any;
  selectedDevice: any;
  isConfirmModalVisible: any;
  onClickConform: any;
  onClickCancelConfirm: any;
  deleteDeviceJob: any;
}

const getDeviceSettingsByDevice = (settings: any, device: string): any => {
  return (settings && device) ? JSON.parse(settings)[device] : { sections: [] };
};

const Main = (props: Props): JSX.Element => {

  const deviceSettings = getDeviceSettingsByDevice(props.queryDeviceSettings?.data?.deviceSettings?.data, props.selectedDevice);

  useEffect(() => {
    if (deviceSettings.sections.length && !Object.keys(props.formValues).length) {
      const initialFormValues: any = {};
      deviceSettings.sections.forEach((section: any) => {
        section.fields.forEach((field: any) => {
          initialFormValues[`field_${field.code}`] = field.default;
        });
      });
      props.setFormValues(initialFormValues);
    }
    /* eslint-disable-next-line */
  }, [deviceSettings]);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <div className={style.headerWrapper}>
            <Paper elevation={2}>
              <div className={style.mainActionsWrapper}>
                <span className={style.label}>Premere questo bottone per inviare i dati ai macchinari</span>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={props.onClickStartWork}
                >
                  Invia dati
                </Button>
              </div>
            </Paper>
          </div>
        </Grid>
        <Grid item xs={12}>
          {
            (
              props.queryDeviceSettings.loading ||
              !props.queryDeviceJobsList.data ||
              props.queryDeviceSettings.loading ||
              !props.queryDeviceJobsList.data ||
              props.queryDevicesList.loading ||
              !props.queryDevicesList.data ||
              props.deleteDeviceJob.loading
            ) ? <CircularProgress /> :
              (
                <Paper>
                  <div className={style.devicesColumns}>
                    {
                      props.queryDevicesList.data.devicesList.map((device: string): JSX.Element => (
                        <div key={`device_column_${device}`}>
                          <div className={style.wrapper}>
                            <div>
                              <span className={style.deviceName} style={{ backgroundColor: getDeviceColor(device) }}>{getDeviceShortName(device)}</span>
                              <span className={style.deviceLabel} style={{ color: getDeviceColor(device) }}>Delco {getDeviceShortName(device)}</span>
                            </div>
                            <div>
                              <Button
                                color="primary"
                                onClick={(): void => props.onClickAddDeviceJob(device)}
                              >
                                Inserisci lavoro
                              </Button>
                            </div>
                          </div>
                          <div className={style.jobsListWrapper}>
                            {
                              !props.queryDeviceJobsList?.data?.deviceJobs?.filter((job: any) => job.device === device)?.length ? (<span className={style.emptyLabel}>Non ci sono dati da mostrare</span>) : <></>
                            }
                            {
                              props.queryDeviceJobsList?.data?.deviceJobs?.filter((job: any) => job.device === device)?.map((job: any) => (
                                <div key={`job_ite_${job.id}`} className={style.jobItemWrapper}>
                                  <div>
                                    <span>{job.description}</span>
                                  </div>
                                  <div>
                                    <span>{job.quantity} <small>kg</small></span>
                                  </div>
                                  <div>
                                    <Button
                                      size="small"
                                      color="secondary"
                                      onClick={(): void => props.onClickDeleteDeviceJob(job.id)}
                                    >
                                      Elimina
                                    </Button>
                                  </div>
                                </div>
                              ))
                            }
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </Paper>
              )
          }
        </Grid>
      </Grid>
      <Dialog
        open={props.isConfirmModalVisible}
        onClose={(): void => undefined}
      >
        <DialogTitle>Inviare i dati?</DialogTitle>
        <DialogContent>
          <span className={style.label}>Sei sicuro di voer inviare i dati ai macchinari?</span>
        </DialogContent>
        <DialogActions>
          <div className={style.actionsWrapper}>
            <div>
              <Button
                color="primary"
                onClick={(): void => props.onClickCancelConfirm()}
              >
                Annulla
              </Button>
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={(): void => props.onClickConform()}
              >
                Invia
              </Button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        open={props.isAddModalVisible}
        onClose={(): void => undefined}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Nuovo lavoro per Delco {getDeviceShortName(props.selectedDevice)}</DialogTitle>
        <DialogContent>
          <div className={style.sectionsWrapper}>
            <div className={style.sectionWrapper}>
              <div className={style.sectionHeaderWrapper}>
                <span>Parametri</span>
              </div>
              <div className={style.sectionContentWrapperLarge}>
                <div className={style.fieldWrapper}>
                  <div className={style.fieldLabelWrapper}>
                    <span>Prodotto</span>
                  </div>
                  <div>
                    <Input
                      type="text"
                      onChange={(event: any): void => props.setFormValues({ ...props.formValues, description: event.target.value })}
                      value={props.formValues.description}
                    />
                  </div>
                </div>
                <div className={style.fieldWrapper}>
                  <div className={style.fieldLabelWrapper}>
                    <span>Kilogrammi da produrre</span>
                  </div>
                  <div>
                    <Input
                      type="number"
                      onChange={(event: any): void => props.setFormValues({ ...props.formValues, quantity: event.target.value })}
                      value={props.formValues.quantity}
                    />
                  </div>
                </div>
              </div>
            </div>
            {
              deviceSettings.sections.map((section: any) => (
                <div key={`device_settings_section_${section.code}`} className={style.sectionWrapper}>
                  <div className={style.sectionHeaderWrapper}>
                    <span>{section.label}</span>
                  </div>
                  <div className={style.sectionContentWrapper}>
                    {
                      section.fields.map((field: any) => (
                        <div key={`device_settings_section_${section.code}_${field.code}`} className={style.fieldWrapper}>
                          <div className={style.fieldLabelWrapper}>
                            <span style={{ opacity: field.status === 0 ? ".5" : "1" }}>{field.label}</span>
                          </div>
                          <div>
                            {
                              field.type === 2 ? (
                                <FormControl variant="outlined" size="small" style={{ width: "100%" }}>
                                  <Select
                                    variant="outlined"
                                    onChange={(event: any): void => props.setFormValues({ ...props.formValues, [`field_${field.code}`]: event.target.value })}
                                    value={props.formValues[`field_${field.code}`]}
                                    disabled={field.status === 0}
                                  >
                                    {
                                      field.options.map((option: any): JSX.Element => (
                                        <MenuItem key={`option_${option}`} value={option.value}>{option.label}</MenuItem>
                                      ))
                                    }
                                  </Select>
                                </FormControl>
                              ) : (
                                <Input
                                  type="number"
                                  onChange={(event: any): void => props.setFormValues({ ...props.formValues, [`field_${field.code}`]: event.target.value })}
                                  value={props.formValues[`field_${field.code}`]}
                                  disabled={field.status === 0}
                                />
                              )
                            }
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              ))
            }
          </div>
        </DialogContent>
        <DialogActions>
          <div className={style.actionsWrapper}>
            <div>
              <Button
                color="primary"
                onClick={(): void => props.onClickCancelAddDeviceJob()}
              >
                Annulla
              </Button>
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={(): void => props.onClickSaveDeviceJob()}
                disabled={!props.formValues?.description || !props.formValues?.quantity}
              >
                Salva
              </Button>
            </div>
          </div>
        </DialogActions>
      </Dialog >
    </>
  );
};

export default Main;
