/* eslint-disable max-len */

import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import style from "./style.module.scss";
import { Box, Button } from "@material-ui/core";
import { getDeviceShortName } from "../../../../utils";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";

interface Props {
  queryFormsDetails: any;
  onClickBackToList: any;
  queryMaterialsList: any;
}

const Details = (props: Props): JSX.Element => {
  if (
    props.queryFormsDetails.loading ||
    !props.queryFormsDetails.data ||
    props.queryMaterialsList.loading ||
    !props.queryMaterialsList.data
  ) {
    return <CircularProgress />;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <div className={style.contentWrapper}>
          <Paper className={style.cardContentWrapper}>
            <Box mb={4}>
              <Button color="primary" onClick={props.onClickBackToList}>
                <ArrowBackIcon />
                <span className={style.backLabel}>Torna alla lista</span>
              </Button>
            </Box>
            <div className={style.threeColumns}>
              <div className={style.fieldWrapper}>
                <span>Operatore</span>
                <b>{props.queryFormsDetails.data.formDetails[0].user.name}</b>
              </div>
              <div className={style.fieldWrapper}>
                <span>Data</span>
                <b>{props.queryFormsDetails.data.formDetails[0].date}</b>
              </div>
              <div className={style.fieldWrapper}>
                <span>Turno</span>
                <b>{props.queryFormsDetails.data.formDetails[0].shift}</b>
              </div>
            </div>
            {
              props.queryFormsDetails.data.formDetails.map((formDetails: any): JSX.Element => (
                <Box key={`device_${formDetails.device}`} mt={4}>
                  <h2 className={style.title}>Delco {getDeviceShortName(formDetails.device)}</h2>
                  <h3 className={style.title}>Materiali prodotti</h3>
                  {
                    formDetails.materials.map((material: any, index: number): JSX.Element => (
                      <div key={`material_${material.material}`} className={style.fourColumns}>
                        {
                          index > 0 ? (
                            <div className={style.fieldWrapper}>
                              <span>Ora</span>
                              <b>{material.time}</b>
                            </div>
                          ) : <></>
                        }
                        <div className={style.fieldWrapper}>
                          <span>Materiale</span>
                          <b>{props.queryMaterialsList.data.materialsList.find((item: any): boolean => item.id === material.material_id.toString())?.name}</b>
                        </div>
                        <div className={style.fieldWrapper}>
                          <span>Quantita</span>
                          <b>{material.quantity_produced}</b>
                        </div>
                        {
                          index > 0 ? (
                            <div className={style.fieldWrapper}>
                              <span>Spurgo</span>
                              <b>{material.quantity_purged}</b>
                            </div>
                          ) : <></>
                        }
                      </div>
                    ))
                  }
                  <h3 className={style.title}>Fermi macchina</h3>
                  {
                    formDetails.stops.length ? (
                      <>
                        {
                          formDetails.stops.map((stop: any): JSX.Element => (
                            <div key={`stop_${stop.start}`} className={style.threeColumns}>
                              <div className={style.fieldWrapper}>
                                <span>Ora inizio</span>
                                <b>{stop.start}</b>
                              </div>
                              <div className={style.fieldWrapper}>
                                <span>Ora fine</span>
                                <b>{stop.end}</b>
                              </div>
                              <div className={style.fieldWrapper}>
                                <span>Motivo</span>
                                <b>{stop.reason}</b>
                              </div>
                            </div>
                          ))
                        }
                      </>
                    ) : <span>Non ci sono fermi macchina</span>
                  }
                </Box>
              ))
            }
            <h2 className={style.title}>Altri dati</h2>
            <div className={style.threeColumns}>
              <div className={style.fieldWrapper}>
                <span>Lame installate</span>
                <b>{props.queryFormsDetails.data.formDetails[0].noteOne}</b>
              </div>
              <div className={style.fieldWrapper}>
                <span>Commenti</span>
                <b>{props.queryFormsDetails.data.formDetails[0].noteTwo}</b>
              </div>
              <div className={style.fieldWrapper}>
                <span>Idoneo</span>
                <b>{props.queryFormsDetails.data.formDetails[0].suitable ? "Idoneo" : "Non idoneo"}</b>
              </div>
            </div>
          </Paper>
        </div>
      </Grid>
    </Grid >
  );
};

export default Details;
