/* eslint-disable max-len */

import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import style from "./style.module.scss";
import Input from "../../../../components/input";

interface Props {
  device: any;
  onChangeDevice: any;
  deviceLiveData: any;
}

const getColumns = (data: any): Array<string> => Object.keys(JSON.parse(data[0].data));

const getRowData = (data: any): Array<string> => Object.keys(JSON.parse(data)).map((key: any) => JSON.parse(data)[key]);

const Main = (props: Props): JSX.Element => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <div className={style.dateCardWrapper}>
          <Paper>
            <div className={style.dateWrapper}>
              <div>
                <Input type="device" value={props.device} onChange={(event: any): void => props.onChangeDevice(event.target.value)} />
              </div>
            </div>
          </Paper>
        </div>
        <Paper className={style.scrollWrapper}>
          {
            props.deviceLiveData.loading
              ? <div className={style.withPadding}><CircularProgress /></div> :
              (
                <>
                  {
                    props.deviceLiveData?.data?.deviceLiveData.length ? (
                      <div className={style.table}>
                        <table>
                          <thead>
                            <tr>
                              {
                                getColumns(props.deviceLiveData.data.deviceLiveData).map((column: string): JSX.Element => (
                                  <th key={"header_" + column}>{column}</th>
                                ))
                              }
                            </tr>
                          </thead>
                          <tbody>
                            {
                              props.deviceLiveData.data.deviceLiveData.map((row: any, index: number) => (
                                <tr key={`row_${index}`}>
                                  {
                                    getRowData(row.data).map((rowData: any, index: number) => (
                                      <td key={"row_data_" + index}>{rowData}</td>
                                    ))
                                  }
                                </tr>
                              ))
                            }
                          </tbody>
                        </table>
                      </div>
                    ) : <span className={style.withPadding}>Non ci sono dati da mostrare</span>
                  }
                </>
              )
          }
        </Paper>
      </Grid>
    </Grid >
  );
};

export default Main;
