import React, { useState } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { MUTATION_LOGIN } from "../../queries";
import Form from "./components/";
import jwt_decode from "jwt-decode";

type Props = RouteComponentProps;

export interface FormValues {
  email: string;
  password: string;
}

const Login = (props: Props): JSX.Element => {
  // Login mutation
  const [login] = useMutation(MUTATION_LOGIN);
  // formValues of login
  const [formValues, setFormValues] = useState<FormValues>({
    email: "",
    password: "",
  });
  // Error of login form
  const [loginError, setLoginError] = useState(false);

  // When the input of login change prepare the form values
  const onChangeForm = (event: any): void => {
    const value = event.target.value;
    const name = event.target.name;
    setFormValues({ ...formValues, [name]: value });
    setLoginError(false);
  };

  // When user press login button
  const onClickLogin = (): void => {
    // If email or password are undefined or "" set error of login true
    if (formValues["email"] === undefined || formValues["password"] === undefined || formValues["email"] === "" || formValues["password"] === "") {
      setLoginError(true);
    } else {
      // Else start the mutation for login
      login({
        variables: { email: formValues["email"], password: formValues["password"] },
      }).then(response => {
        // If response of login is 0 set error of login
        if (response.data.login.status === 0) {
          setLoginError(true);
        } else if (response.data.login.status === 1) {
          // else if user pass the login with status 1 push on shop and set the token of access
          setLoginError(false);
          localStorage.setItem("accessToken", response.data.login.access_token);
          const decodedJwt: any = jwt_decode(response.data.login.access_token);
          if(parseInt(decodedJwt.scopes[0]) === 1) {
            props.history.push("/");
          } else {
            props.history.push("/form");
          }
        }
      }).catch((e: any) => {
        // if get error of mutation set error of login to true
        setLoginError(true);
      });
    }
  };

  return (
    <Form
      formValues={formValues}
      onClickLogin={onClickLogin}
      loginError={loginError}
      onChangeForm={onChangeForm}
    />
  );
};

export default withRouter(Login);
