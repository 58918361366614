/* eslint-disable @typescript-eslint/no-use-before-define */

import React, { useEffect, useState } from "react";
import Main from "./components/main";
import { QUERY_DELTAS_PER_DATE_LIST, QUERY_DEVICES_LIST, QUERY_SHIFTS_LIST, QUERY_FORMS_PER_DATE_LIST } from "../../queries";
import { useLazyQuery } from "@apollo/react-hooks";
import { getCurrentDate, getNextDate, getPreviousDate } from "../../utils";

const Devices = (): JSX.Element => {
  const [date, setDate] = useState(getCurrentDate());

  const [getDeltasPerDateList, queryDeltasPerDateList] = useLazyQuery(QUERY_DELTAS_PER_DATE_LIST, { variables: { date: `${date} 00:00:00` }, fetchPolicy: "no-cache" });

  const [getFormsPerDateList, queryFormsPerDateList] = useLazyQuery(QUERY_FORMS_PER_DATE_LIST, { variables: { date }, fetchPolicy: "no-cache" });

  const [getDevicesList, queryDevicesList] = useLazyQuery(QUERY_DEVICES_LIST, { fetchPolicy: "no-cache" });

  const [getShiftsList, queryShiftsList] = useLazyQuery(QUERY_SHIFTS_LIST, { fetchPolicy: "no-cache" });

  const fetchData = async (): Promise<void> => {
    getDevicesList();
    getDeltasPerDateList();
    getFormsPerDateList();
    getShiftsList();
  };

  const onChangeDate = (date: string): void => setDate(date);

  const onClickPreviousDate = (): void => setDate(getPreviousDate(date));

  const onClickNextDate = (): void => setDate(getNextDate(date));

  useEffect(() => {
    fetchData();
    /* eslint-disable-next-line */
  }, []);

  return (
    <Main
      queryDevicesList={queryDevicesList}
      queryDeltasPerDateList={queryDeltasPerDateList}
      queryFormsPerDateList={queryFormsPerDateList}
      queryShiftsList={queryShiftsList}
      onChangeDate={onChangeDate}
      date={date}
      onClickPreviousDate={onClickPreviousDate}
      onClickNextDate={onClickNextDate}
    />
  );
};

export default Devices;
