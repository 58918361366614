import { TextField } from "@material-ui/core";
import React from "react";

interface Props {
  value: any;
  onChange: any;
  type?: string;
  disabled?: boolean;
}

const Input = (props: Props): JSX.Element => {
  return (
    <TextField
      type={props?.type ?? "text"}
      value={props.value}
      onChange={props.onChange}
      variant="outlined"
      size="small"
      style={{ width: "100%" }}
      disabled={props?.disabled}
    />

  );
};

export default Input;
