/* eslint-disable @typescript-eslint/no-use-before-define */

import React, { useState } from "react";
import Main from "./components/main";
import { QUERY_LOG_SENSORS_PER_DATE_LIST } from "../../queries";
import { useLazyQuery } from "@apollo/react-hooks";

const RawData = (): JSX.Element => {
  const [date, setDate] = useState<string>("");
  const [device, setDevice] = useState<string>("");

  const [getLogSensorsPerDateList, queryLogSensorsPerDateList] = useLazyQuery(QUERY_LOG_SENSORS_PER_DATE_LIST);

  const onChangeDate = (dateValue: string): void => {
    setDate(dateValue);
    fetchData(dateValue, device);
  };

  const onChangeDevice = (deviceValue: string): void => {
    setDevice(deviceValue);
    fetchData(date, deviceValue);
  };

  const fetchData = (dateValue: any, deviceValue: any): void => {
    if (dateValue && deviceValue) getLogSensorsPerDateList({ variables: { date: `${dateValue} 00:00:00`, device: deviceValue } });
  };

  return (
    <Main
      date={date}
      device={device}
      getLogSensorsPerDateList={getLogSensorsPerDateList}
      onChangeDate={onChangeDate}
      onChangeDevice={onChangeDevice}
      queryLogSensorsPerDateList={queryLogSensorsPerDateList}
    />
  );
};

export default RawData;
