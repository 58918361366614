import React from "react";
import Main from "./components/main";

const Plc = (): JSX.Element => {
  return (
    <Main />
  );
};

export default Plc;
