/* eslint-disable @typescript-eslint/no-use-before-define */

import React, { useEffect, useState } from "react";
import AggregatedMonthDeltasTab from "./components/aggregated-month-deltas-tab";
import Header from "./components/header";
import { QUERY_DEVICES_LIST, QUERY_DEVICES_STATUSES, QUERY_AGGREGATED_MONTH_DELTAS, QUERY_SHIFTS_LIST, QUERY_FORMS_PER_DATE_RANGE_LIST } from "../../queries";
import { useLazyQuery } from "@apollo/react-hooks";
import { getCurrentMonthStart, getCurrentMonthEnd, getLastMinutes, getNextMonthEnd, getNextMonthStart, getPreviousMonthEnd, getPreviousMonthStart } from "../../utils";
import FormsPerDateRangeListTab from "./components/forms-per-date-range-list-tab";

const Home = (): JSX.Element => {

  const [range, setRange] = useState<any>({ start: `${getCurrentMonthStart()} 00:00:01`, end: `${getCurrentMonthEnd()} 23:59:59` });

  const [getDevicesStatuses, queryDevicesStatuses] = useLazyQuery(QUERY_DEVICES_STATUSES, {
    variables: getLastMinutes(),
    pollInterval: 5000,
  });

  const [getDevicesList, queryDevicesList] = useLazyQuery(QUERY_DEVICES_LIST);

  const [getShiftsList, queryShiftsList] = useLazyQuery(QUERY_SHIFTS_LIST);

  const [getAggregatedMonthDeltas, queryAggregatedMonthDeltas] = useLazyQuery(QUERY_AGGREGATED_MONTH_DELTAS, {
    variables: { ...range },
  });

  const [getFormsPerDateRangeList, queryFormsPerDateRangeList] = useLazyQuery(QUERY_FORMS_PER_DATE_RANGE_LIST, {
    variables: { ...range },
  });

  const [selectedDay, setSelectedDay] = useState<string | undefined>(undefined);

  const [selectedTab, setSelectedTab] = useState<number>(0);

  const fetchFormsPerDateRangeList = async (): Promise<void> => {
    getFormsPerDateRangeList();
  };

  const fetchData = async (): Promise<void> => {
    getDevicesList();
    getShiftsList();
    getDevicesStatuses();
    getAggregatedMonthDeltas();
  };

  const onOpenTooltip = (day: string): void => {
    if (day === selectedDay) {
      setSelectedDay(undefined);
    } else {
      setSelectedDay(day);
    }
  };

  const onCloseTooltip = (): void => {
    setSelectedDay(undefined);
  };

  const onClickPreviousMonth = (): void => setRange({ start: `${getPreviousMonthStart(range.start)} 00:00:00`, end: `${getPreviousMonthEnd(range.start)} 00:00:00` });

  const onSelectTab = (tab: number): void => {
    setSelectedDay(undefined);

    if (tab === 0) {
      fetchData();
    } else {
      fetchFormsPerDateRangeList();
    }
    setSelectedTab(tab);
  };

  const onClickNextMonth = (): void => setRange({ start: `${getNextMonthStart(range.start)} 00:00:00`, end: `${getNextMonthEnd(range.start)} 00:00:00` });

  useEffect(() => {
    fetchData();
    /* eslint-disable-next-line */
  }, []);

  return (
    <Header
      queryDevicesList={queryDevicesList}
      queryDevicesStatuses={queryDevicesStatuses}
      onSelectTab={onSelectTab}
      selectedTab={selectedTab}
    >
      {
        selectedTab === 0 ? (
          <AggregatedMonthDeltasTab
            queryDevicesList={queryDevicesList}
            queryAggregatedMonthDeltas={queryAggregatedMonthDeltas}
            queryShiftsList={queryShiftsList}
            onOpenTooltip={onOpenTooltip}
            onCloseTooltip={onCloseTooltip}
            selectedDay={selectedDay}
            range={range}
            onClickPreviousMonth={onClickPreviousMonth}
            onClickNextMonth={onClickNextMonth}
          />
        ) : (
          <FormsPerDateRangeListTab
            queryDevicesList={queryDevicesList}
            queryFormsPerDateRangeList={queryFormsPerDateRangeList}
            queryShiftsList={queryShiftsList}
            onOpenTooltip={onOpenTooltip}
            onCloseTooltip={onCloseTooltip}
            selectedDay={selectedDay}
            range={range}
            onClickPreviousMonth={onClickPreviousMonth}
            onClickNextMonth={onClickNextMonth}
          />
        )
      }
    </Header>
  );
};

export default Home;
