import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import style from "./style.module.scss";
import { Box } from "@material-ui/core";

interface Props {
  onClickBackToHome: any;
}

const Form = (props: Props): JSX.Element => {
  return (
    <div className={style.wrapper}>
      <div>
        <Box mb={2}>
          <Typography component="h1" variant="h5">
            Pagina non trovata
          </Typography>
        </Box>
        <Box mb={4}>
          <Typography component="p">
            La pagina che cerchi non esiste, torna alla home.
          </Typography>
        </Box>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={props.onClickBackToHome}
          size="large"
        >
          Torna alla home
        </Button>
      </div>
    </div>
  );
};

export default Form;
