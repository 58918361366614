/* eslint-disable max-len */

import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import style from "./style.module.scss";
import { Clear as ClearIcon } from "@material-ui/icons";
import { Check as CheckIcon } from "@material-ui/icons";
import { FormValues } from "../../";
import { Box, Button, FormControl, TextField } from "@material-ui/core";

interface Props {
  queryUsersList: any;
  onClickSubmit: any;
  formValues: FormValues;
  setFormValues: any;
  isSubmitButtonDisabled: boolean;
  onClickEditUser: any;
  onEditUserId: string;
  onClickReset: any;
  onClickUpdateUserStatus: any;
}

const Main = (props: Props): JSX.Element => {
  if (
    props.queryUsersList.loading ||
    !props.queryUsersList.data
  ) {
    return <CircularProgress />;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box mb={4}>
          <div className={style.formWrapper}>
            <Paper>
              <div className={style.form}>
                <div>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      size="small"
                      variant="outlined"
                      name="nome"
                      label="Nome"
                      type="text"
                      value={props.formValues?.name}
                      onChange={(event: any): void => props.setFormValues({ ...props.formValues, name: event.target.value })}
                    />
                  </FormControl>
                </div>
                <div>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      size="small"
                      variant="outlined"
                      name="email"
                      label="Username"
                      type="text"
                      value={props.formValues?.email}
                      onChange={(event: any): void => props.setFormValues({ ...props.formValues, email: event.target.value })}
                    />
                  </FormControl>
                </div>
                <div>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      size="small"
                      variant="outlined"
                      name="password"
                      label="Password"
                      type="text"
                      value={props.formValues?.password}
                      onChange={(event: any): void => props.setFormValues({ ...props.formValues, password: event.target.value })}
                    />
                  </FormControl>
                </div>
                <div>
                  <Button
                    variant="contained"
                    onClick={props.onClickReset}
                  >
                    Annulla
                  </Button>
                </div>
                <div>
                  <Button
                    variant="contained"
                    onClick={props.onClickSubmit}
                    color="primary"
                    disabled={props.isSubmitButtonDisabled}
                  >
                    {props.onEditUserId ? "Aggiorna" : "Aggiungi"}
                  </Button>
                </div>
              </div>
            </Paper>
          </div>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <div className={style.contentWrapper}>
          <Paper className={style.cardContentWrapper}>
            <div className={style.table}>
              <table>
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>Username</th>
                    <th>Stato</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {
                    props.queryUsersList.data.usersList.map((user: any): JSX.Element => (
                      <tr key={`user_${user.id}`}>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>{user.type.status === 0 ? <ClearIcon color="disabled" /> : <CheckIcon color="primary" className={style.pending} />}</td>
                        <td>
                          <Button
                            color="primary"
                            onClick={(): void => props.onClickEditUser(user.id)}
                            disabled={props.onEditUserId !== "" || user.type.type === 1}
                          >
                            Modifica
                          </Button>
                        </td>
                        <td>
                          <Button
                            color="primary"
                            onClick={(): void => props.onClickUpdateUserStatus(user.id, !user.type.status)}
                            disabled={user.type.type === 1}
                          >
                            {user.type.status === 1 ? "Disabilita" : "Abilita"}
                          </Button>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </Paper>
        </div>
      </Grid>
    </Grid >
  );
};

export default Main;
