import React from "react";
import { Doughnut } from "react-chartjs-2";
import { formatNumber } from "../../utils";

interface Props {
  data: any;
  width?: number;
  height?: number;
}

const PieChart = (props: Props): JSX.Element => {
  return (
    <Doughnut
      data={props.data}
      width={props.width}
      height={props.height}
      options={
        {
          elements: {
            arc: {
              borderWidth: 0,
            },
          },
          cutoutPercentage: 60,
          maintainAspectRatio: true,
          legend: {
            position: "left",
            labels: {
              boxWidth: 14,
              fontColor: "#111111",
              fontStyle: "normal",
              fontSize: 10,
            },
          },
          tooltips: {
            displayColors: false,
            callbacks: {
              label: (item: any, data: any): string => formatNumber(data.labels[item.index]),
              afterLabel: (item: any, data: any): string => formatNumber(data.datasets[0].data[item.index]),
            },
          },
        }
      }
    />
  );
};

export default PieChart;
