/* eslint-disable max-len */

import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import style from "./style.module.scss";
import { Schedule as ScheduleIcon } from "@material-ui/icons";
import { Check as CheckIcon } from "@material-ui/icons";
import { Add as AddIcon } from "@material-ui/icons";
import { formatDate } from "../../../../utils";
import { Button } from "@material-ui/core";

interface Props {
  queryFormsList: any;
  onClickViewForm: any;
  onClickAddForm: any;
}

const Main = (props: Props): JSX.Element => {
  if (
    props.queryFormsList.loading ||
    !props.queryFormsList.data
  ) {
    return <CircularProgress />;
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <div className={style.contentWrapper}>
            <Paper className={style.cardContentWrapper}>
              <div className={style.table}>
                <table>
                  <thead>
                    <tr>
                      <th>Data</th>
                      <th>Turno</th>
                      <th>Operatore</th>
                      <th>Stato</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {
                      props.queryFormsList.data.formsList.map((form: any): JSX.Element => (
                        <tr key={`form_${form.id}`}>
                          <td>{formatDate(form.date)}</td>
                          <td>{form.shift}</td>
                          <td>{form.user.name}</td>
                          <td>{form.status === 1 ? <ScheduleIcon color="disabled" /> : <CheckIcon color="primary" className={style.pending} />}</td>
                          <td>
                            <Button
                              color="primary"
                              onClick={(): void => props.onClickViewForm(form.id)}
                            >
                              Visualizza
                            </Button>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </Paper>
          </div>
        </Grid>
      </Grid>
      <div className={style.addButtonWrapper}>
        <Button
          color="primary"
          variant="contained"
          onClick={props.onClickAddForm}
          className={style.addButton}
        >
          <AddIcon />
        </Button>
      </div>
    </>
  );
};

export default Main;
